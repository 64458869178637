<template>
  <div class="bg-danger w-100">
    <form-container :bg_color="'#DB5555'" :bg_image="'images/fon1.svg'">
      <custom-form :width="380" :top="20">
        <div class="form-body">
          <div class="d-flex width-100 justify-content-center">
            <img src="@/assets/images/1.svg" alt="" height="200" />
          </div>
          <h5 class="text-center">Добро пожаловать!</h5>
          <p class="gray-txt mb-2 w-100 text-center">
            Для начала работы в приложении введите свой номер телефона
          </p>
          <phone-input
            :label="'Номер телефона:'"
            :placeholder="'(___) ___ __ __'"
            v-mask="'+7 (___) ___ __ __'"
            v-model="phone"
          ></phone-input>
          <div class="text mt-3 w-100 text-center">
            Нажимая далее, Вы принимаете условия
            <a href="#">пользовательского соглашения</a> и
            <a href="#">политику обработки персональных данных</a>
          </div>
          <span
            class="p-2 text-danger text-center d-block bold-small rounded mt-2 mb-2 w-100"
            v-if="show_error"
            >{{ error }}</span
          >
        </div>
        <!-- <div class="form-footer"> -->
        <form-footer>
          <a href="/login" class="big-link">Уже есть аккаунт?</a>
          <custom-button
            :text="'Зарегистрироваться'"
            class="button-login"
            @click="setPhone"
            padding=" 1em 3em"
          ></custom-button>
        </form-footer>
        <!-- </div> -->
      </custom-form>
    </form-container>
  </div>
</template>
<script>
import { store } from "@/config/store";
export default {
  name: "SignUpPhonePage",
  data() {
    return {
      phone: "",
      show_error: false,
      error: "",
    };
  },
  methods: {
    setPhone() {
      /*localStorage.setItem('phone', this.phone);*/
      if (this.phone) {
        this.$http.post("/checkPhone", { phone: this.phone }).then((res) => {
          if (res.data.data) {
            this.showError("Этот номер уже занят!");
          } else {
            this.$http
              .post("/send_temporary_password", { phone: this.phone })
              .then(() => {
                if (res.data.success == false) {
                  this.showError(res.data.message);
                } else {
                  store.setPhone(this.phone);
                  console.log("PHONE SET " + this.phone);
                  this.$router.push("signup/password");
                }
              });
          }
        });
      } else {
        this.showError("Введите номер телефона!");
      }
    },
    showError(error) {
      this.error = error;
      this.show_error = true;
      setTimeout(() => {
        this.show_error = false;
      }, 5000);
    },
  },
};
</script>

<style></style>
<style scoped>
h5 {
  margin-top: 32px;
  margin-bottom: 8px;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #292930;
  margin-bottom: 48px;
}

.text a {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #292930;
  text-decoration: underline !important;
}
</style>
