<template>
    <!-- <div class="timer"> -->
        <a :href="href" class="bold-link timer" v-if="timer<1">{{label}}</a>
        <a :href="href" class="bold-link timer" v-else>{{timer}}</a>
    <!-- </div> -->
    
</template>
<script>
export default {
    name: "TimerElement",
    props: {
        href: {
            default: '#'
        },
        label: {
            default: ''
        }
    },
    data() {
        return {
            timer: 60
        }
    },
    methods: {
        countSeconds() {
            this.timer = this.timer > 0 ? this.timer - 1 : 0;
        }
    },
    mounted() {
        setInterval(() => {this.countSeconds()}, 1000);
    }
}
</script>
<style>
.timer {
    margin-right: auto;
    display: flex;
    justify-content: center;
}
</style>