<template>
    <div class="modal-content-section">
        <p class="bold">Контакты заказчика</p>
        <div class="d-flex">
            <div class="content-box content-box_border content-box_bg d-flex w-50 me-2">
                <span class="icon d-flex profile-icon me-2"></span>
                <div class="active-order-text">
                    <p class="gray-small">Имя</p>
                    <p class="bold-small">{{name}}</p>
                </div>
            </div>  
            <div class="content-box content-box_border content-box_bg d-flex w-50">
                <span class="icon d-flex phone-icon me-2"></span>
                <div class="active-order-text">
                    <p class="gray-small">Номер телефона</p>
                    <p class="bold-small">{{phone}}</p>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
export default {
    name: "ClientInfo",
    props: {
        name: {
            default: ''
        },
        phone: {
            default: ''
        }
    }
}
</script>
<style>
.profile-icon {
    background: url('@/assets/images/32.svg') no-repeat center center;
}
</style>
