<template>
    <after-signup-modal v-if="new_user && loaded" v-model="new_user"></after-signup-modal>
    <div v-if="loaded" class="page_wrapper d-flex flex-row pl-3 pr-3">
        <notification-modal v-if="showNotificationsModal" 
        v-model="showNotificationsModal"
        :list="stats.notifications"></notification-modal>
        <div class="content-box content-box_shadow w-75 me-4">
            <dix class="d-flex">
                <p class="bold mt-2">Статистика</p>
                <div class="index_btns d-flex w-25 ms-5">
                    <custom-button :text="'Сегодня'" :padding="'1em'" 
                    :blank="type != 'today'" @click="changeType('today')" :key="type"></custom-button>
                    <custom-button :text="'Неделя'" :padding="'1em'" 
                    :blank="type != 'week'" @click="changeType('week')" :key="type"></custom-button>
                    <custom-button :text="'Месяц'" :padding="'1em'" 
                    :blank="type != 'month'" @click="changeType('month')" :key="type"></custom-button>
                </div>
                
            </dix>
            <div class="container w-100 overflo-hidden modal-content-section">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="content-box pt-2 content-box_border no-snadow">
                            <p class="gray-small">Заказов</p>
                            <p class="bold mt-2">{{stats.stats.orders.stats}}</p>
                        </div>
                    </div>
                    
                    <div class="col-sm-3">
                        <div class="content-box pt-2 content-box_border no-snadow">
                            <p class="gray-small">Среднее время заказа</p>
                            <p class="bold mt-2">{{stats.stats.avgTime.stats}}</p>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="content-box pt-2 content-box_border no-snadow">
                            <p class="gray-small">Повторные заказы</p>
                            <p class="bold mt-2">{{stats.stats.reOrders.stats}}</p>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="content-box pt-2 content-box_border no-snadow">
                            <p class="gray-small">Средний чек</p>
                            <p class="bold mt-2">{{stats.stats.avgCheck.stats}}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3">
                        <div class="content-box pt-2 content-box_border no-snadow">
                            <p class="gray-small">Выручка</p>
                            <p class="bold mt-2">{{stats.stats.proceeds.stats}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="bold mt-4">Выручка</p>
            <div class="chart">
                <Bar :chart-data="stats.graph" 
                :chart-options="chartOptions"
                :width="100"
                :height="400"
                :tooltip="chartTooltip"
                ></Bar>
            </div>
        </div>
        <div class="content_box_list w-25 ">
            <div class="content-box content-box_shadow w-100 mb-3">
                    
                <p class="bold">Уведомления</p>
                <div v-for="(notification,index) in stats.notifications" :key="index"
                    class="content-box content-box_bg content-box_border pt-1 mb-2">
                        <template v-if="index <= 2">
                            <div class="d-flex flex-row">
                                <span class="icon icon-notification"></span>
                                <div class="notification-content">
                                    <div class="d-flex flex-row justify-content-between mb-3">
                                        <p class="bold-small">{{notification.title}}</p>
                                        <p class="gray-small">{{notification.created_at}}</p>
                                    </div>
                                    <p class="gray-small mb-0">{{notification.description}}</p>
                                </div>
                            </div>
                        </template>
                </div>
                <custom-button :blank="true" 
                :right_arrow="true" 
                :padding="0" 
                v-if="newNotifications > 0 && newNotifications <= 10" 
                :text="'Еще {{newNotifications}} новых уведомлений'" 
                :icon="true" @click="showNotificationsModal = true"></custom-button>
                <custom-button :blank="true" 
                :right_arrow="true" 
                :padding="0" v-if="newNotifications > 10" 
                :text="'Еще 10+ новых уведомлений'"
                :icon="true" @click="showNotificationsModal = true"></custom-button>
                <custom-button :blank="true" 
                :right_arrow="true" 
                :padding="0" v-if="newNotifications < 1" 
                :text="'Посмотреть уведомления'"
                :icon="true" @click="showNotificationsModal = true">
                <span class="btn_blue">Посмотреть уведомления</span>
                <span class="icon arrow_blue_icon"></span>
                </custom-button>
            </div>
            <div class="content-box content-box_shadow w-100 mb-3">
                <p class="bold">Заказы на сегодня</p>
                <div v-for="(order, index) in orders" :key="index" class="content-box content-box_bg content-box_border">
                    <div class="modal-content-section d-flex flex-row justify-content-between">
                        <div class="order-desc">
                            <p class="gray-small">Заказ</p>
                            <p class="bold-small">{{order.name}}</p>
                        </div>
                        <div class="order-desc">
                            <p class="gray-small">Выполнение</p>
                            <p class="bold-small">{{order.brigade.title}}</p>
                        </div>
                    </div>
                    <div class="modal-content-section">
                        <p class="gray-small">Откуда</p>
                        <p class="bold-small">{{order.address[0].full}}</p>
                        <p class="gray-small mt-2">Куда</p>
                        <p class="bold-small">{{order.address[1].full}}</p>
                        <div class="d-flex" v-if="order.needRideBack">
                            <span class="icon success-icon"></span>
                            <p class="bold-small mb-0 mt-2" >Обратная поездка</p>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between mt-2">
                        <div class="order-desc">
                            <p class="gray-small">Время</p>
                            <p class="bold-small">{{order.distance.duration}}</p>
                        </div>
                        <div class="order-desc">
                            <p class="gray-small">Стоимость</p>
                            <p class="bold-small">{{order.cost}}</p>
                        </div>
                    </div>
                </div>
                <custom-button :blank="true" 
                :right_arrow="true" 
                :text="'Смотреть все заказы'" 
                :padding="0"
                class="mt-2"
                @click="goToOrders"
                :icon="true">
                    <span class="btn_blue">Посмотреть все заказы</span>
                    <span class="icon arrow_blue_icon"></span>
                </custom-button>
            </div>
            
        </div>
    </div>
</template>
<script>
import {store} from '@/config/store.js'
import {Bar} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default 
{
    name: "IndexPage",
    components: {Bar},
    data() {
        return {
            store,
            new_user: false,
            type: 'today',
            loaded: false,
            stats: [],
            chartTooltip: {
                enabled: false
            },
            chartOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        titleColor: "#777"
                    }
                },
                
                
            },
            newNotifications: 0,
            showNotificationsModal: false,
            orders: []
        }
    },  
    methods: {
        getData() {
            const main_request = this.$http.post('/main', {type: this.type});
            const orders_request = this.$http.get('orders/today');

            this.$http.all([main_request, orders_request]).then(this.$http.spread((...responses) => {
                this.stats = responses[0].data.data;
                this.newNotifications = this.stats.notifications.length - 2;
                this.loaded = true; 
            }))
        },
        changeType(type) {
            this.type = type;
            this.loaded = false;
            this.getData();
        },
        goToOrders() {
            this.$router.push('/orders');
        }
    },
    /*watch: {*/
        /*loaded() {*/
            /*this.$http.get('/users/self').then(res => {*/
                /*if(!res.data.data.name) this.new_user = true;*/
                /*else this.new_user = false;*/
            /*});*/
        /*}*/
    /*},*/
    mounted() {
        this.new_user = store.new_user;
        this.getData();
    }
}
</script>
<style>
.success-icon {
    background: url('@/assets/images/9.svg') no-repeat center center;
}

.index_btns {
    height: 35px;
}

.icon {
    height: 25px;
    width: 25px;
    margin-right: 2px;
    margin-top: 5px;
}
.icon-notification {
    background: url('@/assets/images/6.svg') no-repeat center center;
}

.btn_blue {
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 18px !important;
    color: #3D65BA !important;
    text-align: center !important;
    margin-top: 5px;
}

.arrow_blue_icon {
    background: url('@/assets/images/8.svg') no-repeat center center;
}
</style>

