<template>
    <div class="bg-danger w-100">
        <form-container :bg_color="'#DB5555'" :bg_image="'images/fon1.svg'">
            <custom-form :width="380" :top="20">
            <div class="form-body">
                <div class="d-flex width-100 justify-content-center">
                    <img src='@/assets/images/4.svg' alt="" height="200">
                </div>
                <h5 class="text-center">Введите пароль из СМС</h5>
                <p class="gray-txt">Введите пароль, полученный в СМС при регистрации</p>
                <pass-input v-model="code"></pass-input>
                <div class="text mt-2">Забыли пароль? <a href="#" class="bold-link">Восстановить</a></div>
            </div>
                <form-footer>
                    <a href="/login" class="big-link">Уже есть аккаунт?</a>
                    <custom-button 
                    :href="'#'" 
                    :text="'Войти'" 
                    class="button-login" 
                    @click="login"
                    :padding="'1em'"></custom-button>
                </form-footer>
            </custom-form>
        </form-container>
    </div>
</template>
<script>
// import { store } from '@/config/store';

export default {
    name: "LoginPasswordPage",
    data() {
        return {
            code: ''
        }
    },
    methods: {
        login() {
            const sent_data = {
                'phone': localStorage.getItem('dispatchers_viuviu_phone'),
                'temporary_password': this.code,
                'device_name': 'admin_page'
            }

            this.$http.post('get_mobile_token', sent_data).then(res => {
                // console.log(res);
                this.$auth.login(res.data.token);
            });
        }
    },
    mounted() {
        // console.log(store.user);
    }
}
</script>

<style>
.bold-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #292930;
    margin-left: 4px;
}
</style>

<style scoped>
h5 {
    margin-top: 32px;
    margin-bottom: 8px;
}

.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #292930;
    margin-bottom: 48px;
}

.text a {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #292930;
    text-decoration: underline !important;
}
</style>
