<template>
    <p class="bold">Стоимость</p>
    <div class="d-flex">
        <div class="active-order-cost_left w-50">
            <p class="gray-txt">Установленная стоимость</p>
            <h2 class="mt-2">{{price}}₽</h2>
        </div>
    </div>
</template>
<script>
export default {
    name: "OrderPrice",
    props: {
        price: {
            default: ''
        }
    }
}
</script>
<style></style>
