<template v-if="loaded">
    <custom-modal 
    v-if="notifications.length < 1" 
    title="Уведомления"
    @close="close" 
    :top="'20'">
        <div class="empty-notif">
            <img src="@/assets/images/12.svg" alt="" height="90">
            <span class="empty-notif_text">Уведомления отсутствуют</span>
            <span class="empty-notif_text_blank">Начните выполнение заказа, чтобы возникли первые уведомления</span>
        </div>
    </custom-modal>
    <custom-modal v-else @close="close" :padding="'0 5%'">
        <div v-for="(item, index) in notifications" :key="index" class="notification-box" :class="{'content-box_bg' : !item.read}">
            <div class="d-flex flex-row justify-content-between">
                <!-- <span class="icon icon-notification" ></span> -->
                <div class="d-flex">
                    <img :src="item.type.icon" alt="" height="25" class="d-block m-2">
                    <p class="bold-small mt-1">{{item.title}}</p>
                </div>
                
                <p class="gray-small">{{item.created_at}}</p>
            </div>
            
            <p class="gray-small">{{item.description}}</p>
        </div>
    </custom-modal>
</template>
<script>
import {store} from '@/config/store.js'
export default {
    name: "NotificationModal",
    data() {
        return {
            store,
            notifications: [],
            loaded: false,
            // icon: {
            //     'background': ` url(${require(this)}) repeat center center`
            // }
        }
    },
    methods: {
        close() {
            store.show_notifications = false,
            this.$emit('update:modelValue', false);
        },
        // getIcon(notification) {
        //     return {
        //         'background': 'url(' + notification.type.icon + ') repeat center center !important' 
        //     }
        // }
    },
    mounted() {
        this.$http.get('/notifications').then(res => {
            this.notifications = res.data.data;
            this.loaded = true;
        });
        const read_data = {
            // "id": store.user.id,
            "read": "all"
        }
        setTimeout(() => {
            this.$http.post('/notifications', read_data).then(res => {
                console.log(res.data);
            })
        }, 1000);
        
    }
}
</script>
<style>
.empty-notif {
    padding: 20% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-notif_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #292930;
    margin-bottom: 4px;
}

.notification-box {
    padding: 16px;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: -1px;
    /* display: flex; */
    border-bottom: 0.5px solid #E6E6F0;
}

.empty-notif_text_blank {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #8A8A94;
}
</style>
