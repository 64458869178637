<template>
  <custom-modal :top="'10'" :height="'250'" v-if="delete_modal">
    <div class="d-flex flex-column align-content-center p-4">
      <p class="bold text-center">Вы уверены что хотите удалить заказ?</p>
      <p class="gray-txt text-center">Это действие нельзя будет отменить</p>
      <div class="d-flex justify-content-evenly mt-3">
        <custom-button
          :blank="true"
          :with_border="true"
          :padding="'1em'"
          :text="'Нет'"
          class="w-25"
          @click="delete_modal = false"
        ></custom-button>
        <custom-button
          class="w-25"
          :text="'Да'"
          @click="deleteOrder"
          :padding="'1em'"
        ></custom-button>
      </div>
    </div>
  </custom-modal>
  <div
    class="page_wrapper d-flex flex-row pl-3 pr-3 justify-content-between"
    v-if="loaded"
  >
    <div class="orders_timeline w-50 me-2">
      <!-- Активные В ожидании добавить -->
      <div class="d-flex justify-space-between">
        <h2
          class="bold disabled_type d-block pointer"
          @click="changeType('active')"
          :class="{ active_type: type == 'active' }"
        >
          Активные
        </h2>
        <h2
          class="bold disabled_type d-block pointer"
          @click="changeType('awaiting')"
          :class="{ active_type: type == 'awaiting' }"
        >
          В ожидании
        </h2>
        <!-- <h2 class="bold disabled_type d-block pointer" 
                @click="changeType('new')"
                :class="{'active_type': type == 'new'}">Новые</h2> -->
        <custom-button
          :text="'+'"
          :padding="'10px 0'"
          class="add-order-btn"
          @click="goToCreateOrder()"
        ></custom-button>
      </div>

      <!-- Календарь -->
      <Datepicker
        v-if="type != 'awaiting'"
        v-model="order_date"
        :inline="true"
        menuClassName="order-datepicker"
        calendarClassName="order-datepicker-calendar"
        calendarCellClassName="order-datepicker-cell"
        :locale="'ru'"
        hideOffsetDates
        @internalModelChange="handleDate"
        :monthChangeOnScroll="false"
        :enableTimePicker="false"
      ></Datepicker>

      <!-- Лист с заказами -->
      <div class="content-box content-box_shadow orders-list p-0">
        <div class="orders-list_head">
          <p class="gray-small">Заказ</p>
          <p class="gray-small">Время</p>
          <p class="gray-small">Статус</p>
        </div>
        <!-- <div class="ms-2 me-2"> -->
        <template v-if="orders.orders.current.length > 0">
          <div
            v-for="(order, index) in orders.orders.current"
            :key="index"
            class="orders-list_item d-flex flex-row justify-content-between p-3"
            :class="{
              'order-active border-left-blue': active_order.id == order.id,
            }"
            @click="active_order = order"
          >
            <p class="bold-small">{{ order.name }}</p>
            <div class="order-time">{{ order.distance.duration }}</div>
            <span
              :class="
                'order-status order-status-' + order.status.status.toLowerCase()
              "
              >{{ order.status.text }}</span
            >
            <span class="icon arrow-icon"></span>
          </div>
        </template>
        <template v-else>
          <div class="orders-list_empty">
            <div
              class="no-orders-img d-flex justify-content-center w-100 mt-3 mb-3 flex-column align-items-center"
            >
              <img
                src="@/assets/images/no-table.svg"
                alt=""
                height="150"
                class="d-block mt-3"
              />
              <p class="bold d-block mt-5 text-center">
                Активные заказы отсутствуют
              </p>
              <p class="gray-txt d-block mt-2 text-center ps-4 pe-4">
                Добавьте новые заказы и начните отслеживать статус их выполнения
              </p>
              <custom-button
                :text="'Создать заказ'"
                @click="goToCreate"
                :padding="'1em 0'"
                class="w-50 mt-3 mb-3"
              >
                <!-- <span class="icon add-icon p-0"></span>
                                    <p>Создать заказ</p> -->
              </custom-button>
            </div>
          </div>
        </template>

        <!-- </div> -->
      </div>
    </div>
    <div class="orders_detailed w-50" v-if="active_order">
      <div class="content-box content-box_shadow mt-3">
        <div class="modal-content-section">
          <div
            class="active-order_head d-flex flex-row align-items-center justify-content-between"
          >
            <p class="bold m-0">Заказ №{{ active_order.number }}</p>
            <span
              :class="
                'order-status order-status-' +
                active_order.status.status.toLowerCase()
              "
              >{{ active_order.status.text }}</span
            >
            <div class="d-flex w-50 justify-content-evenly">
              <span
                class="content-box content-box_bg content-box_border position-relative p-1 d-flex flex-row"
              >
                <span class="icon date-icon d-block m-0"></span>
                <p class="bold-small">{{ getMonth() }}</p>
              </span>
              <span
                class="content-box content-box_bg content-box_border position-relative p-1 d-flex flex-row"
              >
                <span class="icon time-icon d-block m-0"></span>
                <p class="bold-small">{{ getTime() }}</p>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-content-section">
          <div class="active-order_body d-flex w-100 mt-3">
            <div class="w-50 me-3">
              <order-route
                :from="active_order.address.from.full"
                :to="active_order.address.to.full"
                :back="active_order.needRideBack"
              >
                <p class="gray-small ms-3">Дополнительные адреса:</p>
                <p
                  class="bold-small ms-3"
                  v-for="(address, index) in additional_addreses"
                  :key="index"
                >
                  {{ address.full }}
                </p>
              </order-route>
            </div>

            <div class="content-box content-box_border content-box_bg w-50">
              <p class="bold">Дополнительные услуги</p>
              <div
                v-if="
                  active_order.climbingStairs &&
                  active_order.climbingStairs.climbingStairs
                "
              >
                <div class="content-box content-box_bg d-flex w-100 me-2">
                  <span class="icon d-flex success-icon"></span>
                  <div class="active-order-text">
                    <p class="gray-small">
                      {{ active_order.climbingStairs.name }}
                    </p>
                    <p class="bold-small">
                      {{ active_order.climbingStairs.text }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  active_order.desStairs && active_order.desStairs.desStairs
                "
              >
                <div class="content-box content-box_bg d-flex w-100 me-2">
                  <span class="icon d-flex success-icon"></span>
                  <div class="active-order-text">
                    <p class="gray-small">{{ active_order.desStairs.name }}</p>
                    <p class="bold-small">{{ active_order.desStairs.text }}</p>
                  </div>
                </div>
              </div>
              <div v-if="active_order.services">
                <div
                  v-for="(service, index) in services"
                  :key="index"
                  class="content-box content-box_bg d-flex w-100 me-2"
                >
                  <span class="icon d-flex success-icon"></span>
                  <div class="active-order-text">
                    <p class="gray-small">{{ service.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <client-info
          :name="active_order.name"
          :phone="active_order.phone"
        ></client-info>
        <div class="modal-content-section">
          <p class="bold">Выполнение</p>
          <div
            class="content-box content-box_border d-flex p-0 overflow-hidden"
          >
            <template v-if="active_order.brigade">
              <img
                :src="active_order.brigade.photos[0].small"
                alt=""
                class="d-block w-25 me-3"
              />
              <div class="active-order_brigade-data w-100 mt-2">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="bold-small m-0">{{ active_order.brigade.title }}</p>
                  <small class="order-status">{{
                    active_order.status.text
                  }}</small>
                  <custom-button
                    :blank="true"
                    :right_arrow="true"
                    :padding="0"
                    :icon="true"
                  >
                    <span class="txt-small btn_blue">Подробнее</span>
                    <span class="icon arrow_blue_icon"></span>
                  </custom-button>
                </div>
                <p class="gray-txt d-block mt-3">Автомобиль</p>
                <p class="bold">
                  {{
                    active_order.brigade.auto != null
                      ? active_order.brigade.auto.model
                      : "--"
                  }}
                </p>
              </div>
            </template>
            <template v-else>
              <p class="bold d-block m-2">
                Для этого заказа не была назначена бригада
              </p>
            </template>
          </div>
        </div>
        <div class="modal-content-section">
          <client-comment :comment="active_order.comment"></client-comment>
        </div>
        <div class="modal-content-section">
          <order-price :price="active_order.cost"></order-price>
        </div>
        <div
          class="active-order-btns d-flex align-items-center justify-content-end pt-3"
        >
          <custom-button
            :blank="true"
            :text="'Удалить'"
            :padding="0"
            class="w-25"
            @click="delete_modal = true"
          ></custom-button>
          <custom-button
            :text="'Редактировать'"
            @click="goToEdit"
            class="w-25"
          ></custom-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "MyOrdersPage",
  components: { Datepicker },
  data() {
    return {
      delete_modal: false,
      loaded: false,
      orders: [],
      type: "active",
      order_date: "2022-11-19",
      active_order: false,
    };
  },
  methods: {
    goToCreate() {
      this.$router.push("/orders/create");
    },
    deleteOrder() {
      this.$http.post("/deleteOrder/" + this.active_order.id).then(() => {
        location.reload();
      });
    },
    goToEdit() {
      this.$router.push("orders/create/" + this.active_order.id);
    },
    changeType(type) {
      this.type = type;
      this.loaded = false;
      this.getData();
    },
    handleDate(date) {
      const new_date = this.$moment(date).format("YYYY-MM-DD");
      if (new_date != this.order_date) this.order_date = new_date;
      this.getData();
    },
    getData() {
      const post_data = {
        date: this.$moment(this.order_date).add(1, "hours").unix() + 10,
        type: this.type,
      };
      this.$http.post("/orders", post_data).then((res) => {
        console.log(res.data);
        console.log(res.data.data);
        this.orders = res.data.data;
        console.log(this.orders.orders.current);
        this.active_order = this.orders.orders.current[0];
        this.loaded = true;
      });
    },
    setDate() {
      this.order_date = this.$moment().format("YYYY-MM-DD");
    },
    getMonth() {
      let dateFormat = "DD.MM.YYYY HH:ii:SS";
      return this.$moment(this.active_order.dateRide, dateFormat)
        .locale("ru")
        .format("DD MMMM");
    },
    getTime() {
      let dateFormat = "DD.MM.YYYY HH:ii:SS";
      return this.$moment(this.active_order.dateRide, dateFormat)
        .locale("ru")
        .format("HH:mm");
    },
    goToCreateOrder() {
      this.$router.push("/orders/create");
    },
  },
  mounted() {
    this.setDate();
    this.getData();
  },
  watch: {
    delete_modal: function (newVal) {
      if (newVal == true) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    additional_addreses() {
      return this.active_order.address.slice(2);
    },
  },
};
</script>
<style>
.disabled_type {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #b6b6bf;
  margin-right: 40px;
  padding-bottom: 6px;
  display: inline-block;
}

.active_type {
  color: #292930 !important;
  border-bottom: 2px solid #db5555;
}

.add-order-btn {
  width: 8%;
}

.order-datepicker {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  border-radius: 16px;
  margin-bottom: 24px;
  overflow: hidden;
  padding: 2em;
  margin-top: 1em;
  width: 80%;
}

.dp__calendar_header {
  width: 100%;
}

.dp__calendar_header_item {
  font-family: "Inter", sans-serif;
  background: none !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8a8a94;
  border-radius: 2px;
}

.dp__action_row {
  display: none;
}

.dp__active_date {
  background: #3d65ba !important;
  color: #fff !important;
}

.order-datepicker-calendar {
  margin-top: 1em;
  width: 100%;
}

.dp__calendar {
  width: 100%;
}

.order-datepicker-cell {
  border: 0;
  margin: 1px;
  height: 3em;
  width: 3em;
}

.orders-list {
  width: 80%;
}

.orders-list_head {
  background: #ececf1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 9px 16px;
}

.orders-list_item:hover,
.order-active {
  background: #ececf1;
}

.order-status {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #8a8a94;
  padding: 4px;
  min-width: 80px;
  background: #ececf1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-status-new,
.brigade-status-free {
  background: #9ece3d;
  color: #fff;
}

.order-status-completed {
  color: #9ece3d;
  background: none;
  border: 1px solid #9ece3d;
}

.order-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #292930;
  padding-left: 28px;
  min-height: 20px;
  display: flex;
  align-items: center;
  background: url("@/assets/images/clock.svg") no-repeat left center;
}

.active-order_head {
  height: 2em;
}

.active-order-cost_left h2 {
  color: #3d65ba;
}

.phone-icon {
  background: url("@/assets/images/33.svg") no-repeat center center;
}
/* .active-order-btns {
    height: 100px;
} */
</style>
