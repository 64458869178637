<template>
  <div class="input-container">
    <label :for="name" v-if="label" class="custom-input-label">{{
      label
    }}</label>
    <div class="custom-input">
      <span>+7</span>
      <input
        type="tel"
        :name="name"
        class="custom-input"
        :placeholder="placeholder"
        :style="styles"
        v-model="phone"
        v-mask="'(###) ###-##-##'"
        @input="passPhone"
      />
    </div>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  name: "PhoneInput",
  props: {
    label: {
      default: false,
    },
    placeholder: {
      default: "",
    },
    value: {
      default: "",
    },
  },
  data() {
    return {
      phone: "",
    };
  },
  methods: {
    passPhone() {
      let onlydigits = this.phone.replace(/[()\s-]/g, "");
      this.$emit("update:modelValue", "7" + onlydigits);
    },
  },
  mounted() {
    this.phone = this.value;
    if (this.phone && this.phone.startsWith("7"))
      this.phone = this.phone.slice(1);
  },
};
</script>
<style>
.input-container {
  width: 100%;
}

.custom-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e6e6f0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.4s;
}

.custom-input input {
  border: 0 !important;
  padding: 0 !important;
  outline: none !important;
  background: none !important;
}

.custom-input span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #292930;
  padding-right: 16px;
  border-right: 1px solid #e6e6f0;
  margin-right: 16px;
}

.custom-input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(39, 39, 41, 0.7);
  transition: all 0.4s;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
