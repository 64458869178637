<template>
    <div class="content-box content-box_border content-box_bg mb-2 p-0">
        <p class="bold m-3">Маршрут</p>
        <div class="border-left-blue ps-3 pe-2 mb-2">
            <p class="gray-small">Откуда</p>
            <p class="bold-small">{{from}}</p>
            <p class="gray-small">Куда</p>
            <p class="bold-small">{{to}}</p>
        </div>
        <slot></slot>
        <div class="d-flex">
            <span class="icon d-block" :class="{'success-icon': back}"></span>
            <p class="bold-small ml-2" v-if="back">Обратная дорога</p>
            <del class="bold-small ml-2" v-else>Обратная дорога</del>
        </div>
        
    </div>
</template>
<script>
export default {
    name: "OrderRoute",
    props: {
        from: {
            default: ''
        },
        to: {
            default: ''
        },
        back: {
            default: false
        }
    }
}
</script>
<style>
.border-left-blue {
    border-left: 2px solid #3D65BA;
}

.success-icon {
    background: url('@/assets/images/31.svg') no-repeat center center;
}
</style>
