<template>
  <div class="page_wrapper" v-if="loaded">
    <create-brigade-modal
      v-model="add_modal"
      v-if="add_modal"
    ></create-brigade-modal>
    <create-brigade-modal
      v-model="edit_modal"
      v-if="edit_modal"
      :edited_brigade="this.active_brigade.id"
    ></create-brigade-modal>
    <custom-modal :top="'10'" :height="'250'" v-if="delete_modal">
      <div class="d-flex flex-column align-content-center p-4">
        <p class="bold text-center">Вы уверены что хотите удалить бригаду?</p>
        <p class="gray-txt text-center">Это действие нельзя будет отменить</p>
        <div class="d-flex justify-content-evenly mt-3">
          <custom-button
            :blank="true"
            :with_border="true"
            :padding="'1em'"
            :text="'Нет'"
            class="w-25"
            @click="delete_modal = false"
          ></custom-button>
          <custom-button
            class="w-25"
            :text="'Да'"
            @click="deleteBrigade"
            :padding="'1em'"
          ></custom-button>
        </div>
      </div>
    </custom-modal>
    <div class="d-flex w-100">
      <div class="w-50">
        <div class="d-flex">
          <h2 class="bold m-0 me-5">Бригады</h2>
          <custom-button
            :text="'Добавить бригаду'"
            :padding="'1em'"
            @click="add_modal = true"
          ></custom-button>
        </div>

        <div class="content-box content-box_shadow orders-list p-0 mt-3">
          <div class="orders-list_head">
            <p class="gray-small">Заказ</p>
            <p class="gray-small">Время</p>
            <p class="gray-small">Статус</p>
          </div>
          <template v-if="brigades.length > 0">
            <div
              v-for="(brigade, index) in brigades"
              :key="index"
              class="orders-list_item d-flex flex-row justify-content-between p-3"
              :class="{
                'order-active border-left-blue':
                  active_brigade.id == brigade.id,
              }"
              @click="active_brigade = brigade"
            >
              <p class="bold-small">{{ brigade.title }}</p>
              <div class="order-time">{{ brigade.fio }}</div>
              <span
                :class="
                  'order-status brigade-status-' +
                  brigade.status.status.toLowerCase()
                "
                >{{ brigade.status.text }}</span
              >
              <span class="icon arrow-icon"></span>
            </div>
          </template>
          <template v-else>
            <div class="orders-list_empty">
              <div
                class="no-orders-img d-flex justify-content-center w-100 mt-3 mb-3 flex-column align-items-center"
              >
                <img
                  src="@/assets/images/22.svg"
                  alt=""
                  height="150"
                  class="d-block mt-3"
                />
                <p class="bold d-block mt-5 text-center">
                  У вас пока нет бригад
                </p>
                <p class="gray-txt d-block mt-2 text-center ps-4 pe-4">
                  Добавьте бригаду, чтобы начать оказывать первую помощь
                  нуждающимся!
                </p>
                <custom-button
                  :text="'Добавить бригаду'"
                  :padding="'1em 0'"
                  class="w-50 mt-3 mb-3"
                  @click="add_modal = true"
                >
                  <!-- <span class="icon add-icon p-0"></span>
                                    <p>Создать заказ</p> -->
                </custom-button>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="w-50">
        <div class="content-box content-box_shadow" v-if="active_brigade">
          <div
            class="d-flex w-100 justify-content-between flex-row modal-content-section"
          >
            <p class="bold">{{ active_brigade.title }}</p>
            <span
              :class="
                'order-status brigade-status-' +
                active_brigade.status.status.toLowerCase()
              "
              >{{ active_brigade.status.text }}</span
            >
          </div>
          <div class="modal-content-section d-flex flex-row w-100">
            <div class="w-50 me-2">
              <div
                class="img_container overflow-hidden mt-1"
                v-for="(img, index) in active_brigade.auto.photo"
                :key="index"
              >
                <img :src="img.original" alt="" class="rounded" height="180" />
              </div>
            </div>
            <div
              class="w-50 ms-2 content-box content-box_bg content-box_border"
            >
              <p class="bold">Об автомобиле</p>
              <p class="gray-small mt-2">Класс авто</p>
              <p class="bold-small">{{ active_brigade.auto.class }}</p>
              <p class="gray-small mt-2">Регистрационный номер</p>
              <p class="bold-small">{{ active_brigade.auto.number }}</p>
              <p class="gray-small mt-2">Автомобиль</p>
              <p class="bold-small">
                {{
                  active_brigade.auto.model
                    ? active_brigade.auto.model.brand.title
                    : ""
                }}
                {{
                  active_brigade.auto.model
                    ? active_brigade.auto.model.title
                    : ""
                }}
              </p>
            </div>
          </div>
          <div class="modal-content-section ps-3 pt-3">
            <p class="bold">Состав бригады</p>
            <div
              class="d-flex w-75"
              v-for="(person, index) in active_brigade.squad"
              :key="index"
            >
              <div class="content-box content-box_bg content-box_border w-50">
                <p class="gray-small">{{ person.position }}</p>
                <p class="bold-small">{{ person.fio }}</p>
              </div>
              <div class="content-box w-50">
                <p class="gray-small">Номер телефона</p>
                <p class="bold-small">{{ person.phone }}</p>
              </div>
            </div>
          </div>
          <div class="modal-content-section d-flex flex-row pt-3">
            <div
              class="content-box content-box_bg content-box_border w-50 me-2"
            >
              <p class="bold">Данные ПТС</p>
              <p class="gray-small mt-2">Серия/номер ПТС</p>
              <p class="bold-small">{{ active_brigade.auto.ptsNumber }}</p>
              <p class="gray-small mt-2">Дата выдачи ПТС</p>
              <p class="bold-small">{{ active_brigade.auto.issueDate }}</p>
              <div
                class="create-brigade_view_document d-flex flex-row"
                v-if="active_brigade.auto.document"
              >
                <img
                  src="@/assets/images/pdf.svg"
                  height="40"
                  class="d-block mt-3 mr-2 p-1"
                />
                <div class="about-brigade_auto_text d-flex flex-column mt-3">
                  <small class="sm-title ml-3">Скан ПТС</small>
                  <span class="gray-txt bold-small ml-3">{{
                    active_brigade.auto.document.filename
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="content-box content-box_bg content-box_border w-50 ms-2"
            >
              <p class="bold">Страховой полис</p>
              <p class="gray-small mt-2">Номер страхового полиса</p>
              <p class="bold-small">{{ active_brigade.auto.serialNumber }}</p>
              <p class="gray-small mt-2">Дата окончания страхового полиса</p>
              <p class="bold-small">{{ active_brigade.auto.endDate }}</p>
              <div
                class="create-brigade_view_document d-flex flex-row"
                v-if="active_brigade.auto.documentInsurance"
              >
                <img
                  src="@/assets/images/pdf.svg"
                  height="40"
                  class="d-block mt-3 mr-2 p-1"
                />
                <div class="about-brigade_auto_text d-flex flex-column mt-3">
                  <small class="sm-title ml-3">Скан страхового полиса</small>
                  <span class="gray-txt bold-small ml-3">{{
                    active_brigade.auto.documentInsurance.filename
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content-section">
            <p class="bold">Комплектация автомобиля</p>
            <div
              class="d-flex align-items-center"
              v-for="(equipment, index) in active_brigade.equipment"
              :key="index"
            >
              <span class="icon success-icon"></span>
              <p class="bold bold-small m-0">{{ equipment.name }}</p>
            </div>
          </div>
          <div class="modal-content-section">
            <p class="bold">Дополнительные услуги</p>
            <div
              class="d-flex align-items-center"
              v-for="(service, index) in active_brigade.services"
              :key="index"
            >
              <span class="icon success-icon"></span>
              <p class="bold bold-small m-0">{{ service.name }}</p>
            </div>
          </div>
          <div class="brigade-btns d-flex mt-3 justify-content-end w-100">
            <custom-button
              class="w-25"
              :text="'Удалить'"
              :blank="true"
              :padding="'1em'"
              @click="delete_modal = true"
            ></custom-button>
            <custom-button
              class="w-25"
              :text="'Редактировать'"
              :padding="'1em'"
              @click="edit_modal = true"
            ></custom-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrigadesPage",
  data() {
    return {
      loaded: false,
      brigades: [],
      active_brigade: "",
      delete_modal: false,
      edit_modal: false,
      add_modal: false,
      new_brigade: {},
    };
  },
  methods: {
    getData() {
      this.$http.get("/brigades").then((res) => {
        this.brigades = res.data.data;
        this.active_brigade = this.brigades[0];
        this.loaded = true;
      });
    },
    deleteBrigade() {
      this.$http.delete("/brigade/" + this.active_brigade.id).then(() => {
        this.$router.push("/dashboard");
      });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    delete_modal: function (newVal) {
      if (newVal == true) {
        window.scrollTo(0, 0);
      }
    },
    add_modal: function (newVal) {
      if (newVal == true) {
        window.scrollTo(0, 0);
      }
    },

    edit_modal: function (newVal) {
      if (newVal == true) {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
