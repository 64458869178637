<template>
    <div class="pass-input-box">
        <label for="pass" class="custom-input-label">Пароль</label>
        <div class="for-input-pass custom-input">
            <input type="text" name="" id="code" @input="enter" placeholder="0">
            <input type="text" name="" id="code" @input="enter" placeholder="0">
            <input type="text" name="" id="code" @input="enter" placeholder="0">
            <input type="text" name="" id="code" @input="enter" placeholder="0">
            
            <!-- <input type="text" class="pass-input"> -->
        </div>
        <input type="hidden" name="pincode" v-model="pincode">
    </div>
    
</template>
<script>
export default {
    name: "LinePassInput",
    data() {
        return {
            pincode: ''
        }
    },
    methods: {
        enter(event) {
            if(event.target.value == '') {
                const inputs = document.getElementsByTagName('input');
                for(const input of inputs) {
                    input.value = "";
                }
                this.pincode = '';
                document.getElementById('code').focus();
            }
            const element = event.target.nextSibling;
            this.pincode = this.pincode + event.target.value;
            element.name.toLowerCase() !== "pincode" ? element.focus() : event.target.blur();
            this.$emit('update:modelValue', this.pincode);
        }
    }
}
</script>
<style>
.for-input-pass {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 12px;
    border: 1px solid #E6E6F0;
    border-radius: 8px;
}
</style>

<style scoped>
input#code {
    width: 25% !important;
    outline: none;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    border-right: 1px solid #E6E6F0 !important;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
}

input#code:last-child {
    border-right: none !important;
}
</style>