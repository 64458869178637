import axios from "axios";
import Auth from "@/config/auth";
// import { store } from "./store";
// const auth = Auth;

// const instance = axios.create({
//     baseURL: "https://drivers.viuviu.ru/api/v1/",
//     headers: {
//         Authorization: 'Bearer ' + localStorage.getItem("dispatchers_viuviu_token")
//     }
// });

axios.defaults.baseURL = 'https://drv.viuviu.ru/api/v1/';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + Auth.token;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://drv.viuviu.ru/';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS';

export default axios;