<template>
    <div class="pass-input">
        <input type="phone" pattern="[0-9]" maxlength="1" name="code[]" id="" class="pass-number" placeholder="0" @input="nextInput">
        <input type="phone" pattern="[0-9]" maxlength="1" name="code[]" id="" class="pass-number" placeholder="0" @input="nextInput">
        <input type="phone" pattern="[0-9]" maxlength="1" name="code[]" id="" class="pass-number" placeholder="0" @input="nextInput">
        <input type="phone" pattern="[0-9]" maxlength="1" name="code[]" id="" class="pass-number" placeholder="0" @input="nextInput">
        <input name="pincode" type="hidden" v-model="pincode">
    </div>
</template>
<script>
export default {
    name: "PassInput",
    data() {
        return {
            pincode: ''
        }
    },
    methods: {
        nextInput(event) {
            if(event.target.value == '') {
                const inputs = document.getElementsByTagName('input');
                console.log(inputs);
                for(const input of inputs) {
                    input.value = "";
                }
                this.pincode = '';
            }
            const element = event.target.nextSibling;
            this.pincode = this.pincode + event.target.value;
            element.name.toLowerCase() !== "pincode" ? element.focus() : event.target.blur();
            this.$emit('update:modelValue', this.pincode);
        }
    }
}
</script>
<style>
.pass-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 12px;
    /* border: 1px solid #E6E6F0; */
    border-radius: 8px;
}

.pass-number {
    border: 1px solid #E6E6F0 !important;
    border-radius: 12px !important;
    padding: 12px 15px 12px 16px !important;
    width: 46px !important;
    margin: 0 5px;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    text-align: center !important;
    color: #292930;
}
</style>