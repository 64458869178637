<template>
  <div class="input-container">
    <label for="time" class="custom-input-label">{{ label }}</label>
    <input
      type="time"
      id="time"
      :class="[modelValue != '' ? 'filled' : 'empty', 'custom-input']"
      :value="modelValue"
      @input="passTime"
      @focusin="openDropdown"
      ref="inp"
      placeholder="чч.мм"
    />
    <span class="custom-input_icon time-icon" @click="openDropdown"></span>
  </div>
</template>
<script>
export default {
  name: "TimeInput",
  props: {
    label: {
      default: "Время",
    },
    modelValue: {
      default: "",
    },
  },
  data() {
    return {
      time: "",
    };
  },
  methods: {
    passTime(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    openDropdown() {
      console.log("openDropdown");
      this.$refs.inp.focus();
      this.$refs.inp.showPicker();
    },
  },
  mounted() {
    console.log("TimeInout mounted, this.modelValue:");
    console.log(this.modelValue);
  },
};
</script>
<style scoped>
.time-icon {
  top: 50%;
  background: url("@/assets/images/clock.svg") no-repeat center center;
  height: 20px;
  width: 20px;
}

.empty::before {
  content: "чч.мм";
  color: grey;
}
.empty {
  color: white;
  animation: none;
}
.empty:focus {
  color: black;
}
.empty:focus::before {
  content: "";
}
.filled:focus::before {
  content: "";
}
.filled {
  color: black;
}
.filled::before {
  content: "";
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
