<template>
  <div class="input-container">
    <label for="date" class="custom-input-label">{{ label }}</label>
    <input
      class="for-date-input"
      :value="modelValue"
      @input="passDate"
      :placeholder="placeholder"
      @focusin="openDatePicker"
    />
    <span class="custom-input_icon date-icon" @click="toggleDatePicker"></span>
    <div class="custom-datepicker" :hidden="!show_datepicker">
      <div class="datepicker_header">
        <custom-select
          :top="60"
          :label="'Месяц'"
          :options="months"
          v-model="month_selected"
        ></custom-select>
        <custom-select
          :top="60"
          :label="'Год'"
          :options="years"
          v-model="year_selected"
        ></custom-select>
      </div>
      <table class="datepicker_body">
        <thead>
          <tr class="week-days">
            <th scope="col" class="gray-txt datepicker_head">
              <span>Пн</span>
            </th>
            <th scope="col" class="gray-txt datepicker_head">
              <span>Вт</span>
            </th>
            <th scope="col" class="gray-txt datepicker_head">
              <span>Ср</span>
            </th>
            <th scope="col" class="gray-txt datepicker_head">
              <span>Чт</span>
            </th>
            <th scope="col" class="gray-txt datepicker_head">
              <span>Пт</span>
            </th>
            <th scope="col" class="gray-txt datepicker_head">
              <span>Сб</span>
            </th>
            <th scope="col" class="gray-txt datepicker_head">
              <span>Вс</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, key) in days" :key="key">
            <tr>
              <td
                v-for="(day, key) in row"
                :key="key"
                :class="[
                  { 'datepicker-day_highlight': this.day_selected == day.date },
                  'datepicker-day',
                ]"
                @click="selectDay(day)"
              >
                {{ day.date }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  // @focusout="closeDatePicker"
  // :value="modelValue.length > 0 ? modelValue : result_date"
  name: "DateInput",
  props: {
    label: {
      default: "Дата",
    },
    modelValue: {
      default: "",
    },
    placeholder: {
      default: "дд.мм.гг",
    },
  },
  data() {
    return {
      months: [
        {
          id: 0,
          name: "Январь",
        },
        {
          id: 1,
          name: "Февраль",
        },
        {
          id: 2,
          name: "Март",
        },
        {
          id: 3,
          name: "Апрель",
        },
        {
          id: 4,
          name: "Май",
        },
        {
          id: 5,
          name: "Июнь",
        },
        {
          id: 6,
          name: "Июльь",
        },
        {
          id: 7,
          name: "Август",
        },
        {
          id: 8,
          name: "Сентябрь",
        },
        {
          id: 9,
          name: "Октябрь",
        },
        {
          id: 10,
          name: "Ноябрь",
        },
        {
          id: 11,
          name: "Декабрь",
        },
      ],
      month_selected: "",
      year_selected: "",
      day_selected: "",
      result_date: "",
      days: [],
      show_datepicker: false,
    };
  },
  methods: {
    passDate(event) {
      console.log("passdate()");
      if (event) {
        this.result_date = event.target.value;
      }
      console.log("this.result_date:");
      console.log(this.result_date);
      this.$emit("update:modelValue", this.result_date);
      // this.$emit("update:value", this.result_date);
    },
    openDatePicker() {
      console.log("this.modelValue:");
      console.log(this.modelValue);
      this.show_datepicker = true;
    },
    closeDatePicker() {
      this.show_datepicker = false;
    },
    toggleDatePicker() {
      this.show_datepicker = !this.show_datepicker;
    },
    selectDay(day) {
      this.day_selected = day.date;
      this.setResultDate();
      this.passDate();
      this.closeDatePicker();
    },
    setDays() {
      let year = this.year_selected ? this.year_selected : this.year;
      let month = this.month_selected ? this.month_selected.id : this.month;
      let date = new Date(year, month, 1);
      let days = [];
      let row = 1;
      while (date.getMonth() === month) {
        if (new Date(date).getDay() == 0) row++;
        let day = {
          week_day: new Date(date).getDay(),
          date: new Date(date).getDate(),
          row: row,
        };
        days.push(day);

        date.setDate(date.getDate() + 1);
      }

      let result = [...Array(row)].map(() => {
        return [...Array(7)].map(() => Object());
      });
      days.forEach((day) => {
        result[day.row - 1][day.week_day] = day;
      });
      this.days = result;
    },
    setResultDate() {
      const day =
        this.day_selected > 9 ? this.day_selected : "0" + this.day_selected;
      const month = this.month_selected
        ? this.month_selected.id + 1
        : this.month;
      const year = this.year_selected ? this.year_selected : this.year;
      this.result_date = day + "." + month + "." + year;
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    month() {
      return new Date().getMonth() + 1;
    },
    years() {
      let years = Array.from({ length: this.year - 2000 }, (_, i) => i + 2001);
      let result = [];
      for (let i = 0; i <= years.length; i++) {
        let year = { id: years[i], name: years[i] };
        result.push(year);
      }
      //   console.log("years():");
      //   console.log(result);

      // 20
      // :
      // {id: 2021, name: 2021}
      // 21
      // :
      // {id: 2022, name: 2022}
      return result;
    },
  },
  watch: {
    year_selected() {
      this.day_selected = "";
      this.setDays();
    },
    month_selected() {
      this.day_selected = "";
      this.setDays();
    },
  },
  mounted() {
    console.log("dateinput mounted, modelValue:");
    console.log(this.modelValue);
    if (this.modelValue) {
      this.result_date = this.modelValue;
    }
    this.setDays();
  },
};
</script>
<style>
.date-icon {
  top: 50%;
  background: url("@/assets/images/date.svg") no-repeat center center;
  height: 20px;
  width: 20px;
}

.for-date-input {
  padding: 13px;
  position: relative;
  width: 100%;
  border: 1px solid #e6e6f0;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
}

.custom-datepicker {
  position: absolute;
  top: 99%;
  left: 5%;
  z-index: 100;
  display: block;
  font-family: "Inter", sans-serif;
  padding: 8px;
  background: #ffffff;
  border: 1px solid #e6e6f0;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
  border-radius: 12px;
  width: 400px;
  cursor: pointer;
}

.datepicker_header {
  display: flex;
}

.datepicker_body {
  width: 100%;
}

.datepicker-day {
  padding: 15px 16px !important;
  text-align: center !important;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #292930;
  margin: 0;
  border: 0;
  background: none;
  border-radius: 8px;
}

.datepicker-day_highlight {
  border-color: #dcdcdc;
  background-color: #3d65ba !important;
  color: #fff !important;
}

.week-days {
  margin-top: 1em;
  /* display: flex;
    justify-content: space-evenly; */
  width: 100%;
}

.datepicker_head {
  font-family: "Inter", sans-serif;
  background: none !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8a8a94;
  border-radius: 2px;
}
</style>

<style scoped>
.date-input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* opacity: 0; */
  background: transparent;
  appearance: none;
  cursor: pointer;
  z-index: 100;
}

.custom-select {
  margin-right: 10px;
}

table {
  margin-top: 1em;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

/* .custom-select_main .custom-input_icon {
    top: 60% !important;
} */
</style>
