<template>
  <custom-modal
    title="Настройки калькулятора"
    @close="close"
    width="550"
    left="30"
  >
    <div class="modal-content-section ms-3 me-3">
      <p class="bold">Маршрут</p>
      <div class="d-flex w-100 justify-content-start gap-3">
        <div class="flex-grow-1">
          <p class="gray-small">Стоимость вызова</p>
          <custom-input
            v-if="editSettings"
            :value="settings.cost"
            v-model="settings.cost"
            placeholder="Стоимость"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p class="bold-small" v-else>{{ settings.cost }}</p>
        </div>
        <div class="flex-grow-1">
          <p class="gray-small">Стоимость за км</p>
          <custom-input
            v-if="editSettings"
            :value="settings.costPerKm"
            v-model="settings.costPerKm"
            placeholder="Стоимость за км"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p v-else class="bold-small">{{ settings.costPerKm }}</p>
        </div>
        <div class="flex-grow-1">
          <p class="gray-small">Ночной тариф</p>
          <custom-input
            v-if="editSettings"
            :value="settings.nightTariff"
            v-model="settings.nightTariff"
            placeholder="Ночной тариф"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p v-else class="bold-small">{{ settings.nightTariff }}</p>
        </div>
      </div>
    </div>
    <div class="modal-content-section ms-3 me-3">
      <p class="bold">Дополнительные услуги</p>
      <div class="d-flex w-100 justify-content-around">
        <div class="w-50">
          <p class="gray-small">Медицинское сопровождение</p>
          <custom-input
            v-if="editSettings"
            :value="settings.medicalSupport"
            v-model="settings.medicalSupport"
            placeholder="Медицинское сопровождение"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p v-else class="bold-small">{{ settings.medicalSupport }}</p>
        </div>
        <div class="w-50">
          <p class="gray-small">Стоимость подъема/спуска</p>
          <custom-input
            v-if="editSettings"
            :value="settings.costFloor"
            v-model="settings.costFloor"
            placeholder="Стоимость подъема/спуска"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p v-else class="bold-small">{{ settings.costFloor }}</p>
        </div>
      </div>
      <p class="bold mt-3">Коронавирус</p>
      <div class="d-flex w-100 justify-content-start">
        <div class="w-50">
          <p class="gray-small">Стабильный</p>
          <custom-input
            v-if="editSettings"
            :value="settings.stabile"
            v-model="settings.stabile"
            placeholder="Стабильный"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p v-else class="bold-small">{{ settings.stabile }}</p>
        </div>
        <div class="w-50">
          <p class="gray-small">Кислородный баллон</p>
          <custom-input
            v-if="editSettings"
            :value="settings.oxygenBalloon"
            v-model="settings.oxygenBalloon"
            placeholder="Кислородный баллон"
            class="mt-1 mb-2 me-1"
          ></custom-input>
          <p v-else class="bold-small">{{ settings.oxygenBalloon }}</p>
        </div>
      </div>
    </div>
    <div class="mt-3 mb-2 d-flex w-100 justify-content-end">
      <custom-button
        text="Сбросить"
        padding="1em"
        :blank="true"
        v-if="!editSettings"
        :with_border="true"
        @click="resetSettings"
        class="w-25"
      ></custom-button>
      <custom-button
        v-if="!editSettings"
        text="Редактировать"
        @click="editSettings = true"
        class="w-50 me-3"
      ></custom-button>
      <span
        v-if="editSettings"
        class="icon-success icon"
        @click="saveSettings"
      ></span>
      <span
        v-if="editSettings"
        class="icon-delete icon"
        @click="removeUpdates"
      ></span>
    </div>
  </custom-modal>
</template>
<script>
export default {
  name: "CalculatorSettingModal",
  data() {
    return {
      settings: [],
      editSettings: false,
    };
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
    getData() {
      this.$http.get("/settingCalculator").then((res) => {
        this.settings = res.data.data;
      });
    },
    resetSettings() {
      const reset = {
        cost: 0,
        costPerKm: 0,
        nightTariff: 0,
        costFloor: 0,
        medicalSupport: 0,
        stabile: 0,
        oxygenBalloon: 0,
      };

      this.$http.post("/settingCalculator", reset).then(() => {
        this.getData();
      });
    },
    saveSettings() {
      this.$http.post("/settingCalculator", this.settings).then(() => {
        this.getData();
        this.editSettings = false;
      });
    },
    removeUpdates() {
      this.getData();
      this.editSettings = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style></style>
