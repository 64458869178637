/**
 * Тут регистрировать ui элементы
 */

import CustomForm from "./CustomForm";
import CustomButton from "./CustomButton";
import PhoneInput from "./inputs/PhoneInput";
import FormContainer from "./FormContainer";
import FormFooter from "./FormFooter";
import PassInput from "./inputs/PassInput";
import LogoViuViu from "./Logo";
import LinePassInput from "./inputs/LinePassInput";
import CustomCheckbox from "./inputs/CustomCheckbox";
import CustomSelect from "./inputs/CustomSelect";
import CustomInput from "./inputs/CustomInput";
import AddressInput from "./inputs/AddressInput";
import CustomFile from "./inputs/CustomFile"; 
import TimeInput from "./inputs/TimeInput"; 
import DateInput from "./inputs/DateInput"; 
import AddInput from "./inputs/AddInput"; 
import RoundImage from './RoundImage';

//Modals
import CustomModal from './modals/Modal';
import NotificationModal from './modals/NotificationModal';
import CompanyInfoModal from './modals/CompanyInfoModal';
import CompanyModal from './modals/CompanyModal';
import CreateBrigadeModal from './modals/CreateBrigadeModal';
import AfterSignupModal from './modals/AfterSignupModal';
import CalculatorSettingsModal from './modals/CalculatorSettingsModal';

export default [
    CustomForm,
    CustomButton,
    PhoneInput,
    FormContainer,
    FormFooter,
    PassInput,
    LogoViuViu,
    LinePassInput,
    CustomCheckbox,
    CustomSelect,
    CustomInput,
    AddressInput,
    CustomFile,
    TimeInput,
    DateInput,
    AddInput,
    RoundImage,
    CustomModal,
    NotificationModal,
    CompanyInfoModal,
    CompanyModal,
    CreateBrigadeModal,
    AfterSignupModal,
    CalculatorSettingsModal
]
