<template>
  <div class="custom-select">
    <div class="custom-select_main input-container">
      <label for="mask" class="custom-input-label">{{ label }}</label>
      <input
        v-if="selected_option.name"
        type="text"
        name="mask"
        :placeholder="placeholder"
        class="custom-input"
        v-model="selected_option.name"
        @focus="show_options = true"
        :class="{ 'custom-input-active': show_options }"
      />
      <input
        v-else
        type="text"
        name="mask"
        :placeholder="placeholder"
        class="custom-input"
        v-model="selected_option.title"
        @focus="show_options = true"
        :class="{ 'custom-input-active': show_options }"
      />
      <span
        class="custom-input_icon arrow-icon"
        @click="show_options = !show_options"
        :style="style"
      ></span>
    </div>

    <ul class="custom-select_options" v-if="show_options">
      <li v-for="option in options" :key="option.id">
        <span :id="option.id" @click="passOption(option)">{{
          option.name ?? option.title
        }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      // type: Object,
      default: [],
    },
    placeholder: {
      default: "Выберите",
    },
    input_name: {
      default: "",
    },
    label: {
      default: "Label",
    },
    top: {
      default: false,
    },
    value: {
      default: false,
    },
  },
  data() {
    return {
      show_options: false,
      selected_option: {},
      style: {
        top: this.top ? this.top + "%" : "55%",
      },
    };
  },
  methods: {
    passOption(option) {
      this.selected_option = option;
      this.show_options = false;
      this.$emit("update:modelValue", this.selected_option);
    },
  },
  mounted() {
    if (this.value) {
      this.options.forEach((option) => {
        if (option.title == this.value || option.name == this.value)
          this.selected_option = option;
      });
    }
  },
};
</script>
<style>
.input-container {
  position: relative;
}

.custom-select_main span {
  cursor: pointer;
}

.custom-select_options {
  margin-top: 0.5em;
  padding-left: 0 !important;
  z-index: 99;
  list-style: none;
  min-width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6f0;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
  border-radius: 12px;
  padding: 4px;
}

.custom-select_options span {
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #292930;
  display: inline-block;
  border-radius: 8px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
}

.custom-select_options span:hover {
  color: #3d65ba;
}

.custom-input_icon {
  position: absolute;
  top: 35%;
  right: 7px;
  height: 15px;
  width: 15px;
  z-index: 99;
  /* background: #000; */
}

.arrow-icon {
  background: url("@/assets/images/blue-arrow.svg") no-repeat center center;
}

.right-arrow-icon {
  top: 20%;
  background: url("@/assets/images/42.svg") no-repeat center center;
}

.custom-input-active {
  border: 1px solid #3d65ba;
}
</style>
