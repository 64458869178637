<template>
  <custom-modal
    :title="'Информация об организации'"
    :width="'500'"
    :height="500"
  >
    <div class="modal-content-section">
      <div class="modal-section_head">
        <p class="bold">Фото</p>
        <custom-button
          :text="'Редактировать'"
          class="section-edit"
          @click="fireFile"
        ></custom-button>
        <input id="company_img" type="file" name="" hidden @input="loadImage" />
      </div>
      <p class="gray-txt">
        Добавьте фото профиля, чтобы заказчикам было проще Вас узнать
      </p>
      <div class="modal-section_head">
        <round-image
          :path="'images/avatar2.svg'"
          :height="'90'"
          :width="'90'"
        ></round-image>
        <div class="section-txt photo-annot">
          <p class="bold">Photo_Doc.png</p>
          <p class="gray-txt">
            Размер файла должен быть не более 20 Мб <br />
            PNG, JPG
          </p>
        </div>
      </div>
    </div>
    <div class="modal-content-section">
      <div class="modal-section_head">
        <p class="bold">Об организации</p>
        <custom-button
          :text="'Редактировать'"
          class="section-edit"
        ></custom-button>
      </div>

      <div class="section-txt with-border">
        <p class="gray-small">Тип организации</p>
        <p class="bold">OOO</p>
      </div>
    </div>
    <div class="modal-content-section">
      <div class="section-txt">
        <p class="gray-small">Название</p>
        <p class="info-txt">Name</p>
      </div>
      <div class="modal-section_head">
        <div class="section-txt">
          <p class="gray-small">ИНН</p>
          <p class="info-txt">Name</p>
        </div>
        <div class="section-txt">
          <p class="gray-small">ОГРН</p>
          <p class="info-txt">Name</p>
        </div>
        <div class="section-txt">
          <p class="gray-small">КПП</p>
          <p class="info-txt">Name</p>
        </div>
      </div>
      <div class="section-txt">
        <p class="gray-txt">Юридический адрес</p>
        <p class="info-txt">Name</p>
      </div>
    </div>
  </custom-modal>
</template>
<script>
export default {
  name: "CompanyInfoModal",

  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
    fireFile() {
      console.log(document.getElementById("company_img"));
      document.getElementById("company_img").click();
    },
    loadImage(e) {
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append("media[]", file);

      this.$http.post("/uploadMedia", formData).then((res) => {
        const avatar = res.data.data.medium;
        this.$http.post("/users/update", { avatar: avatar }).then(() => {
          this.loaded = true;
        });
      });
    },
  },
};
</script>

<style>
.modal-content-section {
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  border-bottom: 1px solid #e6e6f0;
  padding-bottom: 1.5em;
}

.photo-annot {
  margin-top: 2em;
}

.info-txt {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #292930;
}

/* .no-border {
    border: none !important;
}

.with-border {
    
} */

/* .modal-section_head:first-child {
    margin-top: 0.8em;
    border: none;
} */
.modal-section_head {
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
  /* padding-bottom: 1.5em; */
  margin-top: 0.8em;
  margin-bottom: 1em;
  gap: 10px;
}

.section-edit {
  width: 30%;
}

.section-txt {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  /* align-self: flex-end; */
  margin-bottom: 0.2em;

  width: 80%;
}
</style>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
