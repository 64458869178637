/**
 * Тут регистрировать виджеты
 */

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import TimerElement from "./Timer";
import ClientInfo from "./ClientInfo";
import ClientComment from "./ClientComment";
import OrderRoute from "./OrderRoute";
import OrderPrice from "./OrderPrice";

export default [
    Navbar,
    Sidebar,
    TimerElement,
    ClientInfo,
    ClientComment,
    OrderRoute,
    OrderPrice
]
