<template>
    <div class="address-input">
        <div class="address-input input-container">
            <label for="address" class="custom-input-label">{{label}}</label>
            <input type="text" 
            class="custom-input" 
            name="address" 
            v-model="address"
            @input="geocoder">
            <span class="custom-input_icon address-icon"></span>
        </div>
        <ul v-if="suggestions.length > 0" class="custom-select_options">
            <li v-for="(suggestion, index) in suggestions" :key="index" @click="setAddress">
                <span :id="suggestion.id" @click="setAddress">{{ suggestion }}</span>
            </li>
        </ul>
    </div>
    
    
</template>
<script>
export default {
    name: "AddressInput",
    props: {
        label: {
            default: "Юридический адрес:"
        },
        value: {
            default: ''
        },
        search: {
            default: false
        }
    },
    data() {
        return {
            suggestions: [],
            address: null
        }
    },
    methods: {
        passAddress(e) {
            // this.$emit('update:modelValue', e.target.value);
            this.geocoder(e.target.value);
        },

        geocoder(e) {
            if(e.target.value == '') this.suggestions = [];
            if(this.search) {
                let url = '/getSuggest?query=' + e.target.value;

                this.$http.get(url).then(res => {
                    this.suggestions = [];
                    res.data.data.forEach(element => {
                        this.suggestions.push(element.full);
                    });
                })
            }
        },

        setAddress(e) {
            // console.log(e);
            this.address = e.target.innerText;
            this.suggestions = [];
        }
    },
    mounted(){
        this.address = this.value;
    },
    watch: {
        address: function(val) {
            this.$emit('update:modelValue', val);
        }
    }
}
</script>
<style>
.address-icon {
    background: url('@/assets/images/marker.svg') no-repeat center center;
    height: 20px;
    width: 25px;
    top: 50%;
    right: 10px;
}
</style>
