<template>
    <div class="bg-danger w-100">
        <form-container :bg_color="'#DB5555'" :bg_image="'images/fon1.svg'">
            <custom-form :width="380" :top="20">
            <div class="form-body">
                <div class="d-flex width-100 justify-content-center">
                    <img src='@/assets/images/4.svg' alt="" height="200">
                </div>
                <h5 class="text-center">Введите пароль из СМС</h5>
                <p class="gray-txt w-100 text-center">Введите пароль, полученный в СМС</p>
                <pass-input v-model="code"></pass-input>
                <div class="w-100 text-center text">Вы можете повторно получить код через <timer-element :label="'Получить код'"></timer-element></div>
                <span class="p-2 text-danger w-100 text-center bold-small" v-if="show_error">{{error}}</span>
            </div>
                <form-footer>
                    <custom-button :href="'/signup'" :text="'Назад'" class="button-back" :blank="true"></custom-button>
                    <custom-button :text="'Отправить'" class="button-back" padding="1em 3em" @click="signup"></custom-button>
                </form-footer>
            </custom-form>
        </form-container>
    </div>
</template>
<script>
import {store} from '@/config/store';
export default {
    name: "SignUpPasswordPage",
    data() {
        return {
            store,
            code: '',
            show_error: false,
            error: ''
        }
    },
    methods: {
        signup() {
           if(!this.code) this.showError('Введите код!'); 
           else {
                const sent_data = {
                    'phone': localStorage.getItem('dispatchers_viuviu_phone'),
                    'temporary_password': this.code,
                    'device_name': 'admin_page'
                }

                const component = this;
                this.$http.post('get_mobile_token', sent_data).then(res => {
                    if(!res.data.token) {
                        component.showError('Неверный код!');
                    } else {
                        //Отображение модального окна после реги
                        localStorage.setItem('new_user', true);
                        //Метод непосредственного входа
                        this.$auth.login(res.data.token);
                    }
                });
           }
        },
        showError(error) {
            this.error = error;
            this.show_error = true;
            setTimeout(() => {
                this.show_error = false;
            }, 4000);
        }
    }
}
</script>

<style>

</style>
<style scoped>
h5 {
    margin-top: 32px;
    margin-bottom: 8px;
}

.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #292930;
    margin-bottom: 48px;
}

.button-back {
    width: 48%;
}
</style>
