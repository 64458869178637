<template>
    <div class="custom-form" :style="styles">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "CustomForm",
    props: {
        height: {
            default: false
        },
        width: {
            default: false
        },
        padding: {
            default: false
        },
        top: {
            default: 20
        }
    },
    data() {
        return {
            styles: {
                'height': this.height ? this.height + 'px' : 'auto',
                'width': this.width ? this.width + 'px' : 'auto',
                'padding': this.padding ? this.padding : 0,
                'margin-top': this.top + 'px'
            }
        }
    }
}
</script>
<style>
.custom-form {
    box-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
    border-radius: 20px;
    background: #fff;
    position: absolute;
}
</style>