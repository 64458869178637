<template>
    <div class="add-input">
        <div class="custom-add_main input-container">
            <label for="add" class="custom-input-label">{{label}}</label>
            <input 
            type="text" 
            id="target_input"
            class="custom-input" 
            name="add" 
            @input="searchString"
            :placeholder="placeholder" 
            v-model="current">
            <span class="custom-input_icon add-icon" @click="addToList"></span>
        </div>
        <div class="dropdown-menu show w-75 mt-2" v-if="search && search_result.length > 0">
            <a class="search_list_item dropdown-item pointer"
            v-for="(item, index) in search_result" 
            :key="index"
            @click="selectSearch(item.id, $event)">{{item.name}}</a>
        </div>
    </div>
    <div class="add-input_items" v-if="list.length > 0">
        <div class="add-input_item" v-for="(item, index) in list" :key="index">
            <span>{{item.name ?? item}}</span>
            <span class="custom-input_icon delete-icon" @click="deleteFromList" :data-id="index"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: "AddInput",
    props: {
        label: {
            default: ''
        },
        data: {
            default: false
        },
        search: {
            default: false
        }
    },
    data() {
        return {
            current: '',
            list: [],
            search_options: [],
            search_result: [],
        }
    },
    methods: {
        addToList() {
            if(!this.search && this.current.length > 0) {
                this.list.unshift(this.current);
                this.current = '';
                this.$emit('update:modelValue', this.list);
            }
        },

        deleteFromList(e) {
            const index = e.target.dataset.id;

            this.list.splice(index, 1);
            this.$emit('update:modelValue', this.list);
        },

        searchString(e) {
            const q = e.target.value;
            if(!q) this.search_result = [];
            if(q && this.search) {
                console.log(q);
                let filtered_options = this.search_options.filter(option => {
                    console.log(option.name);
                    if(option.name.startsWith(q)) return option;
                });
                console.log(filtered_options);
                this.search_result = filtered_options;
            }
        },
        selectSearch(id) {
            let select;

            this.search_options.forEach(opt => {
                if(opt.id == id) select = opt;
            });

            this.list.unshift(select);
            this.$emit('update:modelValue', this.list);
            //console.log(event.target);
            this.current = '';
            this.search_result = [];
        }
    },
    mounted() {
        if(this.search) {
            this.search_options = this.search;
            /*this.search_result = this.search;*/
        }
        if(this.data) {

            this.list=this.data;
        }
    }
}
</script>
<style>
.add-icon {
    background: url('@/assets/images/blue-plus.svg') no-repeat center center;
    top: 43%;
}

.delete-icon {
    background: url('@/assets/images/del.svg') no-repeat center center;
}

.add-input_items {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.add-input_item {
    margin-top: 1em;
    padding: 16px;
    background: #F7F7FC;
    border: 1px solid #E6E6F0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.add-input_item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #292930;
}
</style>
