<template>
  <custom-modal
    :title="'Создание бригады'"
    :width="'600'"
    :height="'600'"
    :left="'30'"
    @close="close"
  >
    <template v-if="window == 1">
      <div class="modal-content-section red-section">
        <p class="gray-small">
          Назовите бригаду по вашему усмотрению и удобству. Данное название
          будет отображаться только у Вас при отображении бригады.
        </p>
        <custom-input
          :placeholder="'Бригада №1'"
          class="title-brigade"
          v-model="brigade.stepOne.title"
          :value="brigade.stepOne.title"
        ></custom-input>
      </div>
      <div class="modal-content-section ms-3 me-3">
        <p class="bold">Старший бригады</p>
        <p class="gray-small">
          Старшему бригады будет выслан сгенерированный 6-значный пароль для
          входа
        </p>
        <div class="modal-section_head">
          <custom-input
            :label="'Имя Фамилия'"
            v-model="brigade.stepOne.fio"
            :value="brigade.stepOne.fio"
            :placeholder="'Например: Иван'"
            class="driver-brigade"
          ></custom-input>
          <phone-input
            :label="'Номер телефона'"
            class="driver-brigade"
            :value="brigade.stepOne.phone"
            v-model="brigade.stepOne.phone"
          ></phone-input>
        </div>
      </div>
      <div class="ms-3 me-3 modal-content-section">
        <p class="bold">Состав бригады</p>
        <div
          class="brigade-squad-person"
          v-for="(person, index) in brigade.stepOne.squad"
          :key="person"
        >
          <div class="modal-content-section gray-section">
            <div class="modal-section_head">
              <p class="bold">Сотрудник {{ index + 1 }}</p>
              <custom-button
                :blank="true"
                :text="'Удалить'"
                :padding="'0 0'"
                class="modal-link"
                @click="deleteSquad(index)"
              ></custom-button>
            </div>
            <custom-input
              :label="'Имя Фамилия'"
              :placeholder="'Например: Иван'"
              :value="person.fio"
              v-model="person.fio"
            ></custom-input>
            <div class="modal-section_head">
              <phone-input
                :label="'Номер телефона'"
                class="driver-brigade"
                :value="person.phone"
                v-model="person.phone"
              ></phone-input>
              <custom-input
                :label="'Должность'"
                v-model="person.position"
                :value="person.position"
                :placeholder="'Например: Медбрат'"
                class="driver-brigade"
              ></custom-input>
            </div>
          </div>
        </div>
        <custom-button
          :blank="true"
          :with_border="true"
          class="brigade-add"
          :text="'Добавить'"
          @click="addSquad"
        ></custom-button>
      </div>
    </template>

    <template v-if="window == 2">
      <div class="modal-content-section me-3 ms-3">
        <p class="bold">Об автомобиле</p>
        <p class="gray-small">
          Старшему бригады будет выслан сгенерированный 6-значный пароль для
          входа
        </p>
        <custom-select
          :options="auto_classes"
          :label="'Класс авто'"
          v-model="brigade.stepTwo.class"
          :top="56"
          class="mt-2"
          :value="brigade.stepTwo.class.name"
        ></custom-select>
        <div class="modal-section_head">
          <custom-select
            :options="auto_brands"
            :label="'Марка авто'"
            v-model="active_brand"
            :top="56"
            class="driver-brigade"
            :value="active_brand ? active_brand.title : ''"
          ></custom-select>
          <custom-select
            v-if="active_brand"
            :options="active_brand.models"
            :label="'Модель авто'"
            v-model="active_model"
            :top="56"
            class="driver-brigade"
            :value="active_model ? active_model.title : ''"
          ></custom-select>
          <custom-input
            :label="'Регистрационный номер'"
            :placeholder="'A 000 AA 0'"
            class="driver-brigade"
            @input="filterNumber"
            :value="brigade.stepTwo.number"
          ></custom-input>
        </div>
      </div>
      <div class="modal-content-section me-3 ms-3">
        <p class="bold">Страховой полис</p>
        <p class="gray-small">
          Укажите данные страхового полиса, чтобы заказчик мог убедиться в
          безопасности поездки
        </p>
        <div class="modal-section_head">
          <custom-input
            :label="'Номер страхового полиса'"
            :placeholder="'0000000000000'"
            v-model="brigade.stepThree.serialNumber"
            :top="56"
            class="driver-brigade"
            :value="brigade.stepThree.serialNumber"
          ></custom-input>
          <!--<date-input :label="'Дата окончания страхования'" class="driver-brigade" :value="brigade.stepThree.endDate" v-model="brigade.stepThree.endDate"></date-input>-->
          <!-- <custom-input :label="'Регистрационный номер'" :placeholder="'A 000 AA 0'" class="driver-brigade"></custom-input> -->
          <Datepicker
            v-model="brigade.stepThree.endDate"
            inputClassName="create-brigade-datepicker"
            calendarClassName="create-brigade-calendar"
            calendarCellClassName="create-brigade-datepicker-cell"
            :locale="'ru'"
            :close-on-scroll="true"
            hideOffsetDates
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            :auto-apply="true"
            :format="datepicker_format"
          ></Datepicker>
        </div>

        <custom-file
          :label="'Скан страхового полиса'"
          :placeholder="'Выбрать документ'"
          v-model="ensurance"
        ></custom-file>
        <ul class="file-desc">
          <li>Загрузите скан в формате PNG, JPG, JPEG, PDF</li>
          <li>Размер документа не должен превышать 5Мб</li>
        </ul>
      </div>
      <div class="modal-content-section me-3 ms-3">
        <p class="bold">Данные ПТС</p>
        <div class="modal-section_head">
          <custom-input
            :label="'Серия/номер ПТС'"
            :placeholder="'00AA 00000'"
            v-model="brigade.stepFour.number"
            :top="56"
            class="driver-brigade"
            :value="brigade.stepFour.number"
          ></custom-input>
          <Datepicker
            v-model="brigade.stepFour.endDate"
            inputClassName="create-brigade-datepicker"
            calendarClassName="create-brigade-calendar"
            calendarCellClassName="create-brigade-datepicker-cell"
            :locale="'ru'"
            :close-on-scroll="true"
            hideOffsetDates
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            :auto-apply="true"
            :format="datepicker_format"
          ></Datepicker>
          <!-- <custom-input :label="'Регистрационный номер'" :placeholder="'A 000 AA 0'" class="driver-brigade"></custom-input> -->
        </div>

        <custom-file
          :label="'Скан ПТС'"
          :placeholder="'Выбрать документ'"
          v-model="pts"
        ></custom-file>
        <ul class="file-desc">
          <li>Загрузите скан в формате PNG, JPG, JPEG, PDF</li>
          <li>Размер документа не должен превышать 5Мб</li>
        </ul>
      </div>
    </template>

    <template v-if="window == 3">
      <div class="modal-content-section me-3 ms-2">
        <p class="bold">Фото автомобиля</p>
        <p class="gray-small">
          Постарайтесь запечатлить интерьер и экстерьер автомобиля, чтобы у
          заказчика было наиболее полное представление об атомобиле
        </p>
        <ul class="file-desc">
          <li>Загрузите скан в формате PNG, JPG, JPEG, PDF</li>
          <li>Размер документа не должен превышать 5Мб</li>
        </ul>
        <input
          type="file"
          name="photos[]"
          id="brigade-photos"
          multiple
          hidden
          @input="addPhotos"
        />
        <div class="for-images-input rounded border">
          <label for="brigade-photos">
            <img src="@/assets/images/plus_icon.svg" alt="" />
          </label>
        </div>
        <template v-if="edited_brigade && brigade.stepFive.photos.length > 0">
          <div
            class="w-50 position-relative"
            v-for="(img, index) in brigade.stepFive.photos"
            :key="index"
          >
            <img height="100" :src="img.medium" alt="" />
            <img
              src="@/assets/images/delete.svg"
              class="delete-img p-1 rounded"
              alt=""
              @click="deleteImg(index)"
            />
          </div>
        </template>
        <div
          class="filenames p-1"
          v-if="!edited_brigade && brigade.stepFive.photos.length > 0"
        >
          <p
            class="gray-small"
            v-for="(photo, index) in brigade.stepFive.photos"
            :key="index"
          >
            {{ photo.filename }}
          </p>
        </div>

        <p class="gray-small mt-1" v-if="photos.length > 0">
          Загружено {{ this.brigade.stepFive.photos.length }} фото
        </p>
      </div>
    </template>

    <template v-if="window == 4">
      <div class="modal-content-section me-3 ms-2">
        <p class="bold">Комплектация автомобиля</p>
        <!-- <p class="gray-small">Поиск комлектации</p> -->
        <add-input
          :label="'Поиск комлектации'"
          v-model="active_options"
          :data="brigade.stepSix.equipment"
          :search="options"
        ></add-input>
      </div>
      <div class="modal-content-section me-3 ms-2">
        <p class="bold">Дополнительные услуги</p>
        <custom-checkbox
          v-for="(service, index) in services"
          :key="index"
          :background="true"
          :label="service.name"
          :bold="true"
          :options="service.child_services"
          :option_id="service.id"
          :checked="service.checked"
          @checkOption="save_checkbox"
          v-model="service.checked"
        ></custom-checkbox>
        <!-- <custom-checkbox :background="true" :label="'Перевоз короновирусных'" :bold="true" ></custom-checkbox> -->
      </div>
    </template>

    <template v-if="window == 5 && loaded">
      <div class="modal-content-section me-3 ms-2">
        <!-- <p class="bold">{{brigade.stepOne.title}}</p> -->
        <p class="bold">{{ brigade.stepOne.title }}</p>
        <!-- <p class="gray-small">Поиск комлектации</p> -->
        <!-- <add-input :label="'Поиск комлектации'" v-model="options"></add-input> -->
      </div>
      <div class="modal-content-section me-3 ms-2">
        <div class="d-flex mb-2">
          <div
            class="create_brigade_gray w-50 rounded bg-secondary d-flex justify-content-center bg-opacity-25 align-items-center"
          >
            <img
              :src="
                brigade.stepFive.photos[0]
                  ? brigade.stepFive.photos[0].medium
                  : '@/assets/images/not-file.jpg'
              "
              alt=""
              height="200"
            />
          </div>
          <div class="create_brigade_gray w-50 rounded p-3">
            <p class="bold">Об автомобиле</p>
            <div class="about-brigade_auto_text d-flex flex-column">
              <small class="text-danger sm-title">Класс авто</small>
              <span class="gray-txt">{{
                brigade.stepTwo.class ? brigade.stepTwo.class : "Не указан"
              }}</span>
            </div>
            <div class="about-brigade_auto_text d-flex flex-column mt-3">
              <small class="text-danger sm-title">Регистрационный номер</small>
              <span class="gray-txt">{{
                brigade.stepTwo.number ? brigade.stepTwo.number : "Не указан"
              }}</span>
            </div>
            <div class="about-brigade_auto_text d-flex flex-column mt-3">
              <small class="text-danger sm-title">Автомобиль</small>
              <span class="gray-txt">{{
                brigade.stepTwo.model ? brigade.stepTwo.model : "Не указан"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-content-section me-3 ms-2">
        <p class="bold">Состав бригады</p>
        <template v-if="brigade.stepOne.squad.length > 0">
          <div
            class="squad_person d-flex flex-row mt-2"
            v-for="(person, index) in brigade.stepOne.squad"
            :key="index"
          >
            <div class="create_brigade_gray w-50 rounded text-center p-3">
              <p class="gray-small">{{ person.position }}</p>
              <p class="bold-small">{{ person.fio }}</p>
            </div>
            <div class="squad_person_phone w-50 p-3 ml-2">
              <p class="gray-small">Номер телефона</p>
              <p class="bold-small">{{ person.phone }}</p>
            </div>
          </div>
        </template>

        <p class="gray-txt w-25" v-else>Не указано</p>
      </div>
      <div class="modal-content-section ms-2 me-3">
        <div class="d-flex">
          <div class="create_brigade_gray w-50 p-2 rounded mr-2">
            <p class="bold-middle">Данные ПТС</p>
            <div class="about-brigade_auto_text d-flex flex-column mt-3">
              <small class="text-danger sm-title">Серия/номер ПТС</small>
              <span class="gray-txt">{{
                brigade.stepFour.number ? brigade.stepFour.number : "Не указан"
              }}</span>
            </div>
            <div class="about-brigade_auto_text d-flex flex-column mt-3">
              <small class="text-danger sm-title">Дата выдачи ПТС</small>
              <span class="gray-txt">{{
                brigade.stepFour.issueDate
                  ? brigade.stepFour.issueDate
                  : "Не указан"
              }}</span>
            </div>
            <div class="create-brigade_view_document d-flex flex-row">
              <img
                src="@/assets/images/pdf.svg"
                height="40"
                class="d-block mt-3 mr-2 p-1"
              />
              <div class="about-brigade_auto_text d-flex flex-column mt-3">
                <small class="text-danger sm-title ml-3">Скан ПТС</small>
                <span class="gray-txt ml-3">{{
                  brigade.stepFour.document
                    ? brigade.stepFour.document.filename
                    : "Не прикреплено"
                }}</span>
              </div>
            </div>
          </div>
          <div class="create_brigade_gray w-50 p-2 rounded ml-2">
            <p class="bold-middle">Страховой полис</p>
            <div class="about-brigade_auto_text d-flex flex-column mt-3">
              <small class="text-danger sm-title"
                >Номер страхового полиса</small
              >
              <span class="gray-txt">{{
                brigade.stepThree.serialNumber
                  ? brigade.stepThree.serialNumber
                  : "не указан"
              }}</span>
            </div>
            <div class="about-brigade_auto_text d-flex flex-column mt-3">
              <small class="text-danger sm-title"
                >Дата окончания страхования</small
              >
              <span class="gray-txt">{{
                brigade.stepThree.endDate
                  ? brigade.stepThree.endDate
                  : "Не указан"
              }}</span>
            </div>
            <div class="create-brigade_view_document d-flex flex-row">
              <img
                src="@/assets/images/pdf.svg"
                height="40"
                class="d-block mt-3 mr-2 p-1"
              />
              <div class="about-brigade_auto_text d-flex flex-column mt-3">
                <small class="text-danger sm-title ml-3">Скан страховки</small>
                <span class="gray-txt ml-3">{{
                  brigade.stepThree.document
                    ? brigade.stepThree.document.filename
                    : "Не прикреплено"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-content-section me-3 ms-2">
        <p class="bold">Комплектация автомобиля</p>
        <template v-if="brigade.stepSix.equipment.length > 0">
          <div
            class="create_brigade_gray w-100 rounded mb-2 p-3"
            v-for="(item, index) in brigade.stepSix.equipment"
            :key="index"
          >
            <p class="bold-small">{{ item.name }}</p>
          </div>
        </template>
        <p class="gray-txt" v-else>Не указано</p>
      </div>
      <div class="modal-content-section me-3 ms-2">
        <p class="bold">Дополнительные услуги</p>
        <template v-if="brigade.stepSix.additionalServices.length > 0">
          <div
            class="create_brigade_gray w-100 rounded mb-2 p-3"
            v-for="(service, index) in brigade.stepSix.additionalServices"
            :key="index"
          >
            <p class="bold-small">{{ service.name }}</p>
          </div>
        </template>
        <p class="gray-txt" v-else>Не указано</p>
      </div>
    </template>

    <!-- div.custom-modal-contant -->
    <div class="modal-btns">
      <div class="modal-section_head newbrigpopup-btm">
        <custom-button
          :blank="true"
          :with_border="true"
          :text="'Назад'"
          @click="
            () => {
              if (window > 1) window--;
            }
          "
        ></custom-button>
        <custom-button :text="'Дальше'" @click="changeWindow"></custom-button>
      </div>
    </div>
  </custom-modal>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "CreateBrigadeModal",
  components: { Datepicker },
  props: {
    edited_brigade: {
      default: false,
    },
  },
  data() {
    return {
      datepicker_format: "dd.MM.yyyy",
      window: 0,
      brigade: {
        stepOne: {
          title: "",
          phone: "",
          fio: "",
          seniorBrigade: true,
          squad: [
            // {
            //     "fio": "Имя Фамилия",
            //     "phone": 79296214228,
            //     "position": "Мед брад"
            // }
          ],
        },
        stepTwo: {
          class: "",
          number: "",
          model: "",
        },
        stepThree: {
          serialNumber: "",
          endDate: "",
          documentInsurance: {},
        },
        stepFour: {
          number: "",
          issueDate: "",
          document: {},
        },
        stepFive: {
          photos: [],
        },
        stepSix: {
          equipment: [],
          additionalServices: [],
        },
      },
      squad: [],
      auto_classes: [
        {
          id: 1,
          name: "A",
        },
        {
          id: 2,
          name: "B",
        },
      ],
      auto_brands: [],
      ensurance: {},
      ensurance_filename: null,
      pts: {},
      pts_filename: null,
      photos: [],
      options: [],
      active_options: [],
      //brigade_image_block: {
      //'background': ` url('@/assets/images/not-file.jpg')}) no-repeat center center`,
      //  'height': '200px'
      //},
      active_brand: {},
      active_model: {},
      services: [],
      loaded: false,
      id: false,
      stepTwo_number: null,
    };
  },
  methods: {
    handleDate(date) {
      if (!date) return;
      //console.log(date);
      const new_date = new Date(date);
      let dd = new_date.getDate();
      let mm = new_date.getMonth() + 1; // 0 is January, so we must add 1
      let yyyy = new_date.getFullYear();

      let target_date = dd + "." + mm + "." + yyyy;
      return target_date;
      //this.getData();
    },
    handleDates() {
      this.brigade.stepThree.endDate = this.handleDate(
        this.brigade.stepThree.endDate
      );
      this.brigade.stepFour.issueDate = this.handleDate(
        this.brigade.stepFour.endDate
      );
    },
    changeWindow() {
      if (this.window != 5) this.window++;
      else {
        if (this.edited_brigade) {
          const id = this.id;
          this.$http.post("/brigade/" + id, this.brigade).then(() => {
            console.log("edited");
            this.close();
            window.location.reload();
          });
        } else {
          this.$http.post("/addBrigade", this.brigade).then(() => {
            this.close();
            window.location.reload();
          });
        }
      }
    },
    close() {
      this.$emit("update:modelValue", false);
    },
    addSquad() {
      const person = {
        fio: "",
        phone: "",
        position: "",
      };

      this.brigade.stepOne.squad.push(person);
    },

    deleteSquad(index) {
      console.log(index);
      this.squad.splice(index, 1);
    },
    addPhotos(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        let formData = new FormData();
        formData.append("media[]", e.target.files[i]);
        this.$http.post("/uploadMedia", formData).then((res) => {
          this.brigade.stepFive.photos.push(res.data.data[0]);
        });
      }
    },
    deleteImg(index) {
      this.brigade.stepFive.photos.splice(index, 1);
    },
    save_checkbox(option_id, options) {
      this.services.forEach((service) => {
        if (service.id == option_id) {
          service.child_services = options;
        }
      });
    },
    getCheckedServices() {
      let result = [];

      this.services.forEach((service) => {
        if (service.checked) {
          result.push({ id: service.id });
          service.child_services.forEach((child) => {
            if (child.checked) result.push({ id: service.id });
          });
        }
      });

      return result;
    },
    setServices() {
      this.brigade.stepSix.additionalServices = [];
      this.services.forEach((service) => {
        if (service.checked == true) {
          this.brigade.stepSix.additionalServices.push(service);
        }
      });
    },
    setEquipment() {
      this.brigade.stepSix.equipment = [];
      this.active_options.forEach((eq) => {
        this.brigade.stepSix.equipment.push(eq);
      });
    },
    setBrandModel() {
      //TODO IDS!!
      if (this.active_brand.title)
        this.brigade.stepTwo.model = this.active_brand.title + " ";
      if (this.active_model.title)
        this.brigade.stepTwo.model += this.active_model.title;
      if (this.active_model)
        this.brigade.stepTwo.car_models_id = this.active_model.id;
      if (this.brigade.stepTwo.class)
        this.brigade.stepTwo.class = this.brigade.stepTwo.class.name;
    },
    setPhotos() {
      if (this.photos.length > 0) {
        let formData = new FormData();
        let img_count = this.photos.length;
        for (let i = 0; i < img_count; i++) {
          formData.append("media[]", this.photos[i]);
        }
        this.brigade.stepFive.photos = [];
        this.$http
          .post("/uploadMedia", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            res.data.data.forEach((image) => {
              this.brigade.stepFive.photos.push(image);
            });
          });
      }
    },
    setFiles() {
      let sendPts = this.$http.post("/uploadFile", this.pts);
      let sendEns = this.$http.post("/uploadFile", this.ensurance);
      this.$http
        .all([sendPts, sendEns], {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          this.$http.spread((...responses) => {
            this.brigade.stepFour.document = responses[0].data.data
              ? responses[0].data.data
              : {};
            this.brigade.stepThree.documentInsurance = responses[1].data.data
              ? responses[1].data.data
              : {};
            this.loaded = true;
          })
        );
    },
    async setBrigadeData(brigade) {
      this.id = brigade.id;
      let stepOne = {
        fio: brigade.fio,
        phone: brigade.phone,
        title: brigade.title,
        seniorBrigade: true,
        squad: brigade.squad,
      };

      let stepTwo = {
        class: { name: brigade.auto.class },
        number: brigade.auto.number,
        model: brigade.auto.model,
      };

      let stepThree = {
        serialNumber: brigade.auto.serialNumber,
        documentInsurance: brigade.auto.ensuranceDocument
          ? brigade.auto.ensuranceDocument
          : {},
        endDate: brigade.auto.endDate,
      };

      let stepFour = {
        number: brigade.auto.ptsNumber,
        issueDate: brigade.auto.issueDate,
        document: brigade.auto.document ? brigade.auto.document : {},
      };

      let stepFive = {
        photos: brigade.auto.photo,
      };

      let stepSix = {
        equipment: brigade.equipment,
        additionalServices: brigade.services,
      };

      this.brigade.stepOne = stepOne;
      this.brigade.stepTwo = stepTwo;
      this.brigade.stepThree = stepThree;
      this.brigade.stepFour = stepFour;
      this.brigade.stepFive = stepFive;
      this.brigade.stepSix = stepSix;

      if (brigade.auto.model != null) {
        this.active_brand = brigade.auto.model.brand;
        this.active_model = brigade.auto.model;
      }

      this.photos = brigade.photos;
      this.pts = brigade.auto.document;
      this.ensurance = brigade.auto.documentInsurance;
      this.services.forEach((service) => {
        brigade.services.forEach((br_service) => {
          if (service.id == br_service.id) service.checked = true;
        });
      });
      const res = await Promise.resolve("Ready");
      return res;
    },
    async setBrigade() {
      let response = await this.$http.get("/brigade/" + this.edited_brigade);

      let brigade = response.data.data;
      await this.setBrigadeData(brigade);

      this.window = 1;
    },

    filterNumber(e) {
      // let length = e.target.value.length;
      // let val = e.target.value.charAt(length - 1);
      let regex = /^[А-Яа-яdD0-9]+$/;
      let val = e.target.value;

      let match = val.match(regex);
      console.log(match);
      this.brigade.stepTwo.number = e.target.value = match
        ? match.input.toUpperCase()
        : this.brigade.stepTwo.number;
      // console.log(val);
    },
  },

  mounted() {
    if (this.edited_brigade) {
      this.setBrigade(this.setBrigadeData);
    }
    const eq = this.$http.get("/equipment");
    const models = this.$http.get("/autos/models");
    const services = this.$http.get("/services");

    this.$http.all([eq, models, services]).then(
      this.$http.spread((...responses) => {
        //this.options = responses[0].data.data;
        responses[0].data.data.forEach((eq) => {
          eq.items.forEach((item) => {
            this.options.unshift(item);
          });
        });
        this.services = responses[2].data.data;
        this.auto_brands = responses[1].data.data;
        this.window = 1;
      })
    );
  },
  watch: {
    window(val) {
      if (val == 5) {
        this.setServices();
        this.setEquipment();
        this.setBrandModel();
        /*this.setPhotos();*/
        this.handleDates();
        this.setFiles();
      }
    },

    // stepTwo_number(val) {
    //     // let regex = '/[А-Яа-я0-9Dd]+/';
    //     this.brigade.stepTwo.number = val.toUpperCase();
    // }
  },
};
</script>
<style>
.red-section {
  background: #db5555;
  padding: 1em;
  margin-top: -8px;
}

.gray-section {
  background: #f7f7fc;
  width: 100%;
  padding: 1em 1em;
  border-radius: 16px;
  margin-bottom: 10px;
}

.red-section .gray-small {
  color: rgba(255, 255, 255, 0.7);
}

.title-brigade {
  margin-top: 1em;
}

.driver-brigade {
  width: 48%;
}

.modal-link {
  width: 30%;
  margin-top: 5px;
}

/* .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
} */

.brigade-add {
  width: 30%;
  margin-top: 3em;
  margin-left: 35%;
}

.modal-btns button {
  width: 25%;
  justify-self: end;
}

.file-desc {
  margin-top: 1em;
  color: #b6b6bf;
}

.for-images-input {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.for-images-input label {
  cursor: pointer;
}

.create_brigade_gray {
  background: #f7f7fc;
  border: 0.5px solid #e6e6f0;
}

.about-brigade_auto_text .gray-txt {
  text-align: left;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #8a8a94 !important;
}

.about-brigade_auto_text .sm-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0.5em;
}

.delete-img {
  position: absolute;
  bottom: 2px;
  left: 0;
  background: #e3e3e3;
  opacity: 80%;
}

.newbrigpopup-btm {
  padding: 0 1em;
}

.create-brigade-datepicker {
  display: flex;
  margin-top: 1.5em;
  border-radius: 8px;
  height: 3em;
}
</style>
