<template>
    <side-bar v-if="auth"></side-bar>
    <calculator-setting-modal v-if="store.navbar.show_calculator" v-model="store.navbar.show_calculator"></calculator-setting-modal>
    <notification-modal v-if="store.navbar.show_notifications" v-model="store.navbar.show_notifications"></notification-modal>
    <after-signup-modal v-if="store.new_user" v-model="store.new_user"></after-signup-modal>
    <company-modal v-if="store.navbar.show_company" v-model="store.navbar.show_company"></company-modal>
    <div class="content">
      <nav-bar v-if="auth"></nav-bar>
      <router-view/>
    </div>
    
</template>

<script>

import {store} from '@/config/store.js';

export default {
  name: 'App',
  data() {
    return {
        store
    }
  },
  computed: {
    auth() {
      return store.loggedIn;
    },
    new_user() {
        return store.new_user;
    }
  }
}
</script>

<style>
#app {
  display: flex;
  
}

.content {
  width: 100%;
}
</style>
