<template>
    <div class="w-100">
        <form-container :bg_color="'#DB5555'" :bg_image="'images/fon1.svg'">
            <custom-form :width="360" :top="20">
            <div class="form-body">
                <img src='@/assets/images/1.svg' alt="" height="200">
                <h5 class="text-center">С нами удобно!</h5>
                <p class="gray-txt text-center mb-4">Сервис для диспетчеров частных скорых помощей и служб перевозки лежачих больных</p>
                <phone-input :label="'Номер телефона:'" :placeholder="'(___) ___ __ __'" v-model="phone"></phone-input>
                <custom-checkbox 
                :input_name="'remember_me'" 
                :label="'Запомнить меня'" 
                v-model="remember_me"
                :checked="remember_me"
                class="checkbox"></custom-checkbox>
            </div>
            <span class="text-danger bold-small d-block w-100 justify-content-center text-center" v-if="show_errors">{{error}}</span>
            <form-footer>
                <a href="/signup" class="big-link">Зарегистрироваться</a>
                <custom-button :text="'Войти'" class="button-login" :right_arrow="true" :with_border="true" @click="login" :padding="'12px 0'"></custom-button>
            </form-footer>
            </custom-form>
        </form-container>
    </div>
</template>
<script>
import {store} from '@/config/store';

export default {
    name: "LoginPhonePage",
    data() {
        return {
            // showModal: true,
            remember_me: false,
            phone: '',
            show_errors: false,
            error: ''
        }
    },
    methods: {
        login() {
            this.$http.post('/checkPhone', {phone: this.phone}).then(res => {
                if(res.data.data == false) {
                    this.showErrors('Вы не зарегистрированы!');
                } else {
                    this.$http.post('/send_temporary_password', {phone: this.phone}).then(res => {
                        if(res.status == 200) {
                            store.setPhone(this.phone, this.remember_me);
                            // console.log(store.user);
                            this.$router.push('/login/password');
                        }
                    });
                }
            })

        }, 
        showErrors(error) {
            this.show_errors = true;
            this.error = error;
            setTimeout(() => {
                this.show_errors = false;
            }, 4000);
        }
    }
}
</script>

<style>
.checkbox {
    flex-direction: row;
    display: flex;
}


.form-body {
    padding: 8px 41px;
}

.form-body .checkbox{
    margin-top: 1em;
}

.button-login {
    margin-left: auto;
    padding: 12px 14px 12px 18px;
    width: 48%;
}

.big-link {
    padding: 12px 24px;
    color: #DB5555;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
}
</style>


<style scoped>
h5 {
    margin-top: 32px;
    margin-bottom: 8px;
}
</style>
