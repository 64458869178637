<template>
  <div class="modal_wrapper">
    <div class="custom-modal-bg">
      <div class="custom-modal" :style="modal_css">
        <div class="custom-modal_head">
          <slot v-if="custom_header"></slot>
          <h2 v-else>{{ title }}</h2>
          <span class="custom-input_icon close-icon" @click="close"></span>
        </div>
        <div class="custom-modal_body" :style="padding">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomModal",
  props: {
    title: {
      default: "",
    },
    top: {
      default: 5,
    },
    left: {
      default: 40,
    },
    width: {
      default: 400,
    },
    height: {
      default: 450,
    },
    custom_header: {
      default: false,
    },
    padding: {
      default: "0.5em 5%",
    },
  },
  data() {
    return {
      modal_css: {
        top: this.top + "%",
        left: this.left + "%",
        width: this.width + "px",
        height: this.height + "px",
      },
    };
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
<style>
.modal_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.close-icon {
  background: url("@/assets/images/close.svg") no-repeat center center;

  opacity: 80%;
}

.custom-modal-bg {
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 100;
  background: rgba(119, 119, 119, 0.425);
  /* opacity: 20%; */
}

.custom-modal {
  position: absolute;
  overflow-y: scroll;
  /* top: 5%; */
  /* left: 40%; */
  /* width: 400px; */
  box-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
  border-radius: 20px;
  background: #fff;
  -ms-overflow-style: -ms-autohiding-scrollbar !important; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* .custom-modal::-webkit-scrollbar {
  display: none;
} */

.custom-modal_head {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #db5555;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.close-icon {
  position: relative !important;
}
.custom-modal_head h2 {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

/* .custom-modal_body {
    padding: 0.5em 5%;
} */
</style>
