<template>
    <div class="custom-file input-container">
        <label for="file" class="custom-input-label">{{label}}</label>
        <div class="for-file-input">
            <span for="custom-file" class="ms-4 filename gray-small">{{ filename }}</span>
        </div>
        <input type="file" name="file" id="" class="custom-input custom-input_file" @change="passFile($event)">
        <div class="input-file flex">
            <span for="custom-file" class="custom-input_icon icon-left file-icon"></span>
            <span for="custom-file" class="custom-input_icon upload-icon"></span>
        </div>
        
    </div>
</template>
<script>
export default {
    name: "CustomFile",
    props: {
        label: {
            default: "Label"
        },
        // filename: {
        //     default: ''
        // }
    },
    data() {
        return {
            filename: null
        }
    },
    methods: {
        passFile(e) {
            let result = new FormData();
            // console.log(e.target.files[0]);
            this.filename = e.target.files[0].name;
            result.append('file', e.target.files[0]);
            console.log(result);
            //console.log(e.target.files);

            this.$emit('update:modelValue', result);
        }
    }
}
</script>
<style>
.file-icon {
    background: url('@/assets/images/docs_input.svg') no-repeat center center;
    height: 20px;
    width: 20px;
}

.file-icon::after {
    content: '';
    height: 100%;
    width: 1px;
    background: #9a9999;
    display: block;
    margin-left: 25px;
}

.upload-icon {
    background: url('@/assets/images/download.svg') no-repeat center center;
    height: 20px;
    width: 20px;
    top: 50%;
}

.icon-left {
    left: 5px;
    top: 50%;
}

.for-file-input {
    padding: 18px;
    position: relative;
    width: 100%;
    border: 1px solid #E6E6F0;
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
}

.custom-input_file {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    background: transparent;
    appearance: none;
    cursor: pointer;
}
</style>
