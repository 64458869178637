<template>
   <div class="row justify-content-end">
        <form-container :bg_color="'#DB5555'" :bg_image="'images/fon1.svg'">
   
        </form-container>
        <div class="main_login_half main_login_text col-md-6">
            <div class="main_login_text_content">
                <logo-viu-viu></logo-viu-viu>
                <h3 class="h3-red">С нами удобно!</h3>
                <date-input></date-input>
                <p>Сервис для диспетчеров частных скорых помощей и служб перевозки лежачих больных</p>
                <img src="@/assets/images/1.svg" alt="">
            </div>
        </div>
        <div class="main_login_half main_login_form col-md-6">
            <div class="main_login_text_content">
                <h3 class="h3-form">Добро пожаловать в ВиуВиу Work</h3>
                <phone-input :label="'Номер телефона:'" :placeholder="'(___) ___ __ __'" class="main_login_input"></phone-input>
                <line-pass-input class="main_login_input"></line-pass-input>
                <div class="main_login_links">
                    <custom-checkbox :input_name="'remember_me'" :label="'Запомнить меня'" class="checkbox"></custom-checkbox>
                    <a href="#" class="main_login_forgot grey-link">Забыли пароль?</a>
                </div>
                
                <custom-button :href="'/login/password'" :text="'Войти'" class="button-login main_login_button"></custom-button>
            </div>
        </div>
        
   </div> 
  
</template>
<script>


export default 
{
    name: "MainLoginPage"
}
</script>
<style>
.main_login_links {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.main_login_button {
    margin-right: auto;   
    margin-top: 2em;
}
.main_login_input {
    padding-bottom: 1em;
}

.main_login_half {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
}

.main_login_text_content {
    width: 80%;
    max-width: 482px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main_login_form {
    background: #fff;
    height: 100vh;
}
</style>

<style scoped>
.h3-red {
    color: #FFFFFF;
    margin-top: 32px;
    margin-bottom: 8px;
}

.h3-form {
    color: #111;
    margin-top: 32px;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 40px;

}

p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 24px;
}
</style>