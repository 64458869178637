<template>
    <p class="bold">Комментарий от заказчика</p>
    <div class="content-box content-box_bg content-box_border">
        <p class="gray-txt">{{comment}}</p>
    </div>
</template>
<script>
export default {
    name: "ClientComment",
    props: {
        comment: {
            default: ''
        }
    }
}
</script>
<style></style>
