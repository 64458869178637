<template>
  <div class="sidebar">
    <logo-viu-viu :red="true"></logo-viu-viu>
    <ul class="sidebar_items">
      <li class="sidebar_items-item">
        <a
          href="/dashboard"
          class="sidebar_items-link"
          :class="{ active: path == '/dashboard' }"
        >
          <img src="@/assets/images/home.svg" alt="" />
          <span>Главная</span>
        </a>
      </li>
      <!-- <li class="sidebar_items-item">
                <a href="#" class="sidebar_items-link"
                :class="{'active' : path == '/orders/available'}">
                    <img src="@/assets/images/docs.svg" alt="">
                    <span>Доступные заказы</span>
                </a>
            </li> -->
      <li class="sidebar_items-item">
        <a
          href="/orders"
          class="sidebar_items-link"
          :class="{ active: path == '/orders' }"
        >
          <img src="@/assets/images/list.svg" alt="" />
          <span>Мои заказы</span>
        </a>
      </li>
      <li class="sidebar_items-item">
        <a
          href="/brigades"
          class="sidebar_items-link"
          :class="{ active: path == '/brigades' }"
        >
          <img src="@/assets/images/car.svg" alt="" />
          <span>Бригады</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  // mounted() {
  //     console.log(this.$route.path);
  // }
  computed: {
    path() {
      return this.$route.path;
    },
  },
};
</script>
<style>
.sidebar {
  /* position: absolute;
    left: 0;
    top: 0; */
  z-index: 96;
  width: 160px;
  height: 100vh;
  background: #292930;
}

.sidebar_items {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 60px;
}

.sidebar_items-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(230, 230, 240, 0.1);
}

.sidebar_items-link span {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 4px;
  transition: all 0.4s;
}
</style>

<style scoped>
.logo {
  margin-top: 1em;
  margin-left: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

a.active {
  background: #db5555;
  border-color: transparent;
}
</style>
