<template>
    <div class="logo">
        <a href="/">
            <img src="@/assets/images/logo-red.svg" alt="" v-if="red">
            <img src="@/assets/images/logo.svg" alt="" v-else>
        </a>
    </div>
</template>
<script>
export default {
    name: "LogoViuViu",
    props: {
        red: {
            default: false
        }
    }
}
</script>
<style>
.logo {
    height: 40px;
}
</style>