<template>
    <div class="round-image" :style="styles">

    </div>
</template>
<script>
export default {
    name: "RoundImage",
    props: {
        path: {
            default: ''
        },
        url: {
            default: ''
        },
        height: {
            default: 30,
        },
        width: {
            default: 30,
        }
    },
    data() {
        return {
            styles: {
                'height': this.height + 'px',
                'width': this.width + 'px',
                'background': this.path ? ` url(${require('@/assets/' + this.path)}) no-repeat center center` : ` url(${this.url}) no-repeat center center`,
                'background-size': '100% 100%'
            }
        }
    }
}
</script>
<style>
.round-image {
    border-radius: 100%;
    z-index: 100;
}
</style>
