<template>
    <div class="form-container" :style="styles">
        <slot></slot>
    </div>
</template>
<script>
// import { url } from 'inspector';

export default {
    name: 'FormContainer',
    props: {
        bg_image : {
            default: ''
        },
        bg_color: {
            default: '#fff'
        }
    },
    data() {
        return {
            styles: {
                'background': this.bg_color + ` url(${require('@/assets/' + this.bg_image)}) repeat center center`
            }
        }
    }
}
</script>
<style>
.form-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    z-index: -1;
}
</style>