<template>
    <a :href="href" :class="{'is_disabled' : disabled}" v-if="href">
        <button class="btn-red" :style="styles" :disabled="disabled" :class="{'blank_button' : blank}">
            <slot v-if="icon"></slot>
            <template v-else>
                <img src="@/assets/images/5.svg" alt="" class="svg" :style="span" v-if="left_arrow">
                <span :style="span">{{ text }}</span>
                <img src="@/assets/images/2.svg" alt="" class="svg" :style="span" v-if="right_arrow">
            </template>
            
        </button>
    </a>
    <button class="btn-red" :style="styles" :disabled="disabled" :class="{'blank_button' : blank}" v-else>
        <slot v-if="icon"></slot>
        <template v-else>
            <img src="@/assets/images/5.svg" alt="" class="svg" :style="span" v-if="left_arrow">
            <span :style="span">{{ text }}</span>
            <img src="@/assets/images/2.svg" alt="" class="svg" :style="span" v-if="right_arrow">
        </template>
        
    </button>
</template>
<script>
export default {
    name: "CustomButton",
    props: {
        href: {
            default: false
        },
        text: {
            default: "click"
        },
        //Button types
        blank: {
            default: false
        },
        disabled: {
            default: false
        },
        with_border: {
            default: false
        },
        border_color: {
            default: '#DB5555' 
        },
        left_arrow: {
            default: false
        },
        right_arrow: {
            default: false
        },
        with_hover: {
            default: false
        },
        icon: {
            default: false
        },
        padding: {
            default: '12px 65px'
        }
    },
    data() {
        return {
            styles: {
                'background-color': this.disabled ? 'rgba(219, 85, 85, 0.4)' : '#DB5555', 
                'border': this.with_border ? '1px ' + this.border_color + ' solid' : 'none',
                'padding': this.padding
            },
            span: {
                color: this.blank ? '#DB5555' : '#fff',
                'font-weight' : 600,
            }
        }
    }
}
</script>

<style>
.blank_button {
    background-color: inherit !important;
}

.is_disabled {
    color: currentColor;
  cursor: not-allowed;
  opacity: 0.8;
  text-decoration: none;
  pointer-events: none;
}

.btn-red {
    /* background: #DB5555; */
    border-radius: 12px;
    /* padding: 12px 62px 12px 67px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
    outline: none;
    border: none;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    align-content: center;
}

.btn-red:hover {
    background: #C14141;
    color: #fff;
}

.btn-red span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}


</style>