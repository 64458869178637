<template>
    <custom-modal :title="'Тип организации'" :width="'500'" :height="'300'" :left="'35'" @close="close">
        <button class="after-signup_btn btn position-relative border" v-if="window" @click="window = false">
            <span class="custom-input_icon right-arrow-icon"></span>
        </button>
        <div class="after_signup_list ps-2 pe-2" v-if="!window">
            <div class="gray-bg p-3 position-relative pointer" @click="goTo(1)">
                <p class="bold-middle">OOO</p>
                <span class="custom-input_icon arrow-icon"></span>
            </div>
            <div class="gray-bg p-3 position-relative pointer" @click="goTo(2)">
                <p class="bold-middle">Индивидуальный предприниматель</p>
                <span class="custom-input_icon arrow-icon"></span>
            </div>
            <div class="gray-bg p-3 position-relative pointer" @click="goTo(3)">
                <p class="bold-middle">Физическое лицо / самозанятый</p>
                <span class="custom-input_icon arrow-icon"></span>
            </div>
        </div>
        <div class="modal-content-section after_signup_ooo ps-2 pe-2" v-if="window == 1">
            <p class="bold-middle mb-2">Об организации</p>
            <custom-input :label="'Название'" 
            :placeholder="'Например: Initech'" v-model="company.name" 
            :value="company.name" class="mb-2"></custom-input>
            <div class="d-flex flex-row mb-2">
                <custom-input :label="'ИНН'" 
                :placeholder="'000000000000'" 
                v-model="company.inn" 
                :value="company.inn"></custom-input>
                <custom-input :label="'ОГРН'" 
                :placeholder="'000000000000'" 
                v-model="company.ogrn" 
                :value="company.ogrn"></custom-input>
            </div>
            <custom-input class="w-50 mb-2" 
            :label="'КПП'" 
            :placeholder="'000000000000'"
            :value="company.kpp" 
            v-model="company.kpp"></custom-input>
            <address-input 
            v-model="company.legalAddress" 
            :value="company.legalAddress"
            class="mb-2"></address-input>
            <address-input :label="'Фактический адрес'" 
            v-model="company.address"
            :value="company.address"
            ></address-input>
        </div>
        <div class="modal-content-section after_signup_individual ps-2 pe-2" v-if="window == 2">
            <p class="bold-middle mb-2">Об организации</p>
            <custom-input :label="'Название'" 
            :placeholder="'Например: Initech'" v-model="company.name" 
            :value="company.name" class="mb-2"></custom-input>
            <div class="d-flex flex-row mb-2">
                <custom-input :label="'ИНН'" 
                :placeholder="'000000000000'" 
                v-model="company.inn" 
                :value="company.inn"></custom-input>
                <custom-input :label="'ОГРН'" 
                :placeholder="'000000000000'" 
                v-model="company.ogrn" 
                :value="company.ogrn"></custom-input>
            </div>
            <!-- <custom-input class="w-50 mb-2" 
            :label="'КПП'" 
            :placeholder="'000000000000'"
            :value="company.depCode" 
            v-model="company.depCode"></custom-input> -->
            <address-input v-model="company.legalAddress" 
            :value="company.legalAddress" class="mb-2"></address-input>
            <!-- <address-input :label="'Фактический адрес'" v-model="company.address"></address-input> -->
        </div>
        <div class="modal-content-section after_signup_fisical ps-2 pe-2" v-if="window == 3">
            <p class="bold-middle mt-2 mb-2">О предпринимателе</p>
            <div class="d-flex flex-row mb-2">
                <custom-input :label="'ФИО'" 
                :placeholder="'Например: Иванов Иван Иванович'"
                v-model="company.name" :value="company.name"></custom-input>
                <custom-input :label="'ИНН'" 
                :placeholder="'000000000000'"
                v-model="company.inn" :value="company.inn"></custom-input>
            </div>
            <p class="bold-middle mt-2 mb-2">Паспортные данные</p>
            <div class="d-flex flex-row mb-2 mt-2">
                <custom-input :label="'Серия и номер паспорта'" 
                :placeholder="'0000 000000'"
                v-model="company.serialAndNumber" :value="company.serialAndNumber"
                class="w-50"></custom-input>
                <date-input :label="'Дата рождения'" 
                v-model="company.birthDay" 
                :value="company.birthDay"
                class="w-50"></date-input>
            </div>
            <custom-input :label="'Кем выдан'"
            v-model="company.issuedBy" 
            :value="company.issuedBy" 
            :placeholder="'Например: УВД по г.Сочи'"
            class="mb-2"></custom-input>
            <div class="d-flex flex-row mb-2">
                <date-input :label="'Дата выдачи'" 
                class="w-50"
                :value="company.issueDate" 
                v-model="company.issueDate"></date-input>
                <custom-input class="w-50" :label="'Код подразделения'"
                v-model="company.depCode" :value="company.depCode" :placeholder="'000-000'"></custom-input>
            </div>
        </div>
        <div class="after_signup_btns d-flex justify-content-end ps-2 pe-2" v-if="window && window < 4">
            <custom-button :text="'Продолжить'" :right_arrow="true" class="w-25 mt-2" :disabled="company.name.length < 1" :key="company.name" @click="submit"></custom-button>
        </div>
        <template v-if="window == 4">
            <h3 class="text-success w-100 text-center mt-3">Данные успешно отправлены.</h3>
        </template>
    </custom-modal>
</template>

<script>
import DateInput from '../inputs/DateInput.vue';
export default {
  components: { DateInput },
    name: "AfterSignupModal",
    data() {
        return {
            window: false,
            company: {
                "birthDay": "",
                "company_type": "",
                "depCode": "",
                "inn": "",
                "issueDate": "",
                "issuedBy": "",
                "name": "",
                "serialAndNumber": "",
                "address": "",
                "legalAddress": "",
                "kpp": ""
            }
        }
    },
    methods: {
        submit() {
            this.$http.post('/users/update', {company: this.company}).then(() => {
                this.window = 4;
                setTimeout(() => {this.close()}, 4000);
            })
        },
        goTo(win) {
            if(win == 1) this.company.company_type = 'OOO';
            if(win == 2) this.company.company_type = 'I';
            if(win == 3) this.company.company_type = 'F';
            console.log(this.company.company_type);

            this.window = win;
        },
        close() {
            localStorage.removeItem('new_user');
            this.$emit('update:modelValue', false);
        }
    }, 
}
</script>
<style>
.after-signup_btn {
    height: 27px;
    width: 27px;
}
</style>
