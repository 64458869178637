<template>
  <div class="page_wrapper" v-if="loaded">
    <div class="d-flex align-items-center">
      <div
        class="content-box content-box_border pointer p-1 me-2"
        @click="goToMyOrders"
      >
        <span class="icon arrow-left-icon d-block"></span>
      </div>
      <h2 class="font-weight-bold m-0">Создание заказа</h2>
    </div>
    <div class="d-flex mt-4">
      <!--  SIDEBAR -->
      <div class="w-25 content-box content-box_shadow me-3 p-0">
        <ul class="brigade-menu p-0">
          <li
            class="brigade-menu_item d-flex justify-content-between w-100 p-2 ps-3"
            :class="{ 'menu-item_active': window == 1 }"
            @click="window = 1"
          >
            <p class="bold-small m-0">Место и время</p>
            <span class="icon arrow-right-icon mt-0"></span>
          </li>
          <li
            class="brigade-menu_item d-flex justify-content-between w-100 p-2 ps-3"
            :class="{ 'menu-item_active': window == 2 }"
            @click="window = 2"
          >
            <p class="bold-small m-0">Дополнительные услуги</p>
            <span class="icon arrow-right-icon mt-0"></span>
          </li>
          <li
            class="brigade-menu_item d-flex justify-content-between w-100 p-2 ps-3"
            :class="{ 'menu-item_active': window == 3 }"
            @click="window = 3"
          >
            <p class="bold-small m-0">Личные данные</p>
            <span class="icon arrow-right-icon mt-0"></span>
          </li>
          <li
            class="brigade-menu_item d-flex justify-content-between w-100 p-2 ps-3 bgredcustom"
            :class="{
              'menu-item_active': window == 4,
              'bg-secondary': !order_completed,
            }"
            @click="window = 4"
          >
            <p class="bold-small m-0">О заказе</p>
            <span class="icon arrow-right-icon mt-0"></span>
          </li>
        </ul>
      </div>

      <!--  CONTENT -->
      <div class="w-75 content-box content-box_shadow">
        <template v-if="window == 1">
          <div class="modal-content-section">
            <p class="bold">Место</p>
            <div class="w-50">
              <address-input
                v-model="order.address.from.full"
                :value="order.address.from.full"
                label="Место отправления"
                class="mt-2"
                :search="true"
              >
              </address-input>
              <address-input
                v-model="order.address.to.full"
                :value="order.address.to.full"
                label="Место назначения"
                class="mt-2"
                :search="true"
              >
              </address-input>
              <address-input
                v-for="(address, index) in order.address.extra"
                :key="index"
                v-model="address.full"
                :value="address.full"
                class="mt-2"
                :search="true"
              >
              </address-input>
              <custom-button
                :blank="true"
                :with_border="true"
                :text="'+ Добавить адрес'"
                class="mt-2"
                @click="addAddress"
              ></custom-button>
              <custom-checkbox
                v-model="order.needRideBack"
                :checked="order.needRideBack"
                class="mt-3"
                :label="'Нужна поездка обратно'"
              ></custom-checkbox>
            </div>
          </div>
          <div class="modal-content-section">
            <p class="bold">Время</p>
            <div class="d-flex mt-3">
              <date-input v-model="dateRide.date" class="w-50 me-2">
              </date-input>
              <!-- <VueDatePicker :time-picker="true"> </VueDatePicker> -->
              <!-- <VueTimepicker
                v-model="dateRide.time"
                :value="dateRide.time"
                format="HH:mm"
                :input-class="['w-100', 'for-date-input', 'p-4']"
                class="mt-4 w-50 ms-2"
                placeholder="чч.мм"
                close-on-complete
              ></VueTimepicker> -->
              <time-input
                v-model="dateRide.time"
                class="w-50 ms-2"
              ></time-input>
            </div>
          </div>
        </template>
        <template v-if="window == 2">
          <div class="modal-content-section">
            <p class="bold">Дополнительные услуги</p>
            <div class="w-50">
              <custom-checkbox
                :checked="climbingStairs.checked"
                v-model="climbingStairs.checked"
                :bold="true"
                :background="true"
                :label="'Подъем по лестнице'"
              ></custom-checkbox>
              <div
                v-if="climbingStairs.checked"
                class="w-75 content-box mt-1 p-0 d-flex"
              >
                <custom-input
                  :value="climbingStairs.floor"
                  v-model="climbingStairs.floor"
                  :placeholder="'Этаж'"
                  :label="'Этаж'"
                  class="mb-1 me-2"
                ></custom-input>
                <custom-input
                  :value="climbingStairs.weight"
                  v-model="climbingStairs.weight"
                  :placeholder="'Вес'"
                  :label="'Вес'"
                ></custom-input>
              </div>
              <custom-checkbox
                :checked="desStairs.checked"
                v-model="desStairs.checked"
                :bold="true"
                :background="true"
                :label="'Спуск по лестнице'"
              ></custom-checkbox>
              <div
                v-if="desStairs.checked"
                class="w-75 content-box mt-1 p-0 d-flex"
              >
                <custom-input
                  :value="desStairs.floor"
                  v-model="desStairs.floor"
                  :placeholder="'Этаж'"
                  :label="'Этаж'"
                  class="mb-1 me-2"
                ></custom-input>
                <custom-input
                  :value="desStairs.weight"
                  v-model="desStairs.weight"
                  :placeholder="'Вес'"
                  :label="'Вес'"
                ></custom-input>
              </div>
              <custom-checkbox
                v-for="(service, index) in services"
                :key="index"
                :label="service.name"
                :background="true"
                :bold="true"
                v-model="service.selected"
                :checked="service.selected"
              ></custom-checkbox>
            </div>
          </div>
        </template>
        <template v-if="window == 3">
          <div class="modal-content-section">
            <p class="bold">Личные данные</p>
            <div class="d-flex mt-2">
              <custom-input
                :label="'Имя'"
                :value="order.name"
                v-model="order.name"
                :placeholder="'Например: Иван'"
                class="me-2"
              >
              </custom-input>

              <phone-input
                :value="order.phone"
                v-model="order.phone"
                :label="'Номер телефона'"
                class="ms-2"
              >
              </phone-input>
            </div>
          </div>
        </template>
        <template v-if="window == 4">
          <div class="modal-content-section">
            <p class="bold m-0 mb-3">Комплектация автомобиля</p>
            <div class="d-flex w-100">
              <div
                class="p-0 content-box w-50 me-2 content-box_bg content-box_border"
              >
                <p class="bold m-3">Маршрут</p>
                <div class="border-left-blue ps-3">
                  <p class="gray-txt">Откуда</p>
                  <p class="bold">{{ order.address.from.full }}</p>
                  <p class="gray-txt">Куда</p>
                  <p class="bold">{{ order.address.to.full }}</p>
                  <p class="gray-txt">Дополнительные адреса:</p>
                  <p
                    class="bold"
                    v-for="(address, index) in order.address.extra"
                    :key="index"
                  >
                    {{ address.full }}
                  </p>
                </div>
                <div class="d-flex">
                  <span class="icon d-block success-icon"></span>
                  <p class="bold-small ml-2">Обратная дорога</p>
                </div>
              </div>

              <div
                class="content-box w-50 ms-2 content-box_border content-box_bg"
              >
                <p class="bold m-0">Дополнительные услуги</p>
                <div v-if="climbingStairs.checked">
                  <div class="content-box content-box_bg d-flex w-100 me-2">
                    <span class="icon d-flex success-icon"></span>
                    <div class="active-order-text">
                      <p class="gray-small">Подъем по лестнице</p>
                      <p class="bold-small">
                        {{ climbingStairs.floor }} этаж
                        {{ climbingStairs.weight }}кг
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="desStairs.checked">
                  <div class="content-box content-box_bg d-flex w-100 me-2">
                    <span class="icon d-flex success-icon"></span>
                    <div class="active-order-text">
                      <p class="gray-small">Спуск по лестнице</p>
                      <p class="bold-small">
                        {{ desStairs.floor }} этаж {{ desStairs.weight }}кг
                      </p>
                    </div>
                  </div>
                </div>
                <div v-for="(service, index) in services" :key="index">
                  <div
                    v-if="service.selected"
                    :key="index"
                    class="content-box content-box_bg d-flex w-100 me-2 align-items-center"
                  >
                    <span class="icon d-flex success-icon"></span>
                    <div class="active-order-text">
                      <p class="m-0 bold-small">{{ service.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content-section">
            <p class="bold">Контакты заказчика</p>
            <div class="d-flex">
              <div
                class="content-box content-box_border content-box_bg d-flex w-50 me-2"
              >
                <span class="icon d-flex profile-icon me-2"></span>
                <div class="active-order-text">
                  <p class="gray-small">Имя</p>
                  <p class="bold-small">{{ order.name }}</p>
                </div>
              </div>
              <div
                class="content-box content-box_border content-box_bg d-flex w-50"
              >
                <span class="icon d-flex phone-icon me-2"></span>
                <div class="active-order-text">
                  <p class="gray-small">Номер телефона</p>
                  <p class="bold-small">{{ order.phone }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content-section">
            <p class="bold">Выполнение</p>
            <div class="d-flex flex-column justify-content-center mt-2">
              <!--<custom-button
                            :blank="true"
                            class="w-25 mb-2"
                            :with_border="true"
                            :text="'+ Выбрать бригаду'"
                            :padding="'1em'"
                            :disabled="order.self_brigade"
                            @click="select_brigade = true"
                            ></custom-button> -->
              <custom-checkbox
                class="w-50"
                :checked="order.self_brigade"
                v-model="order.self_brigade"
                label="Заказ без бригады"
              ></custom-checkbox>
              <custom-select
                :label="'Выбрать бригаду'"
                v-if="!order.self_brigade"
                :options="brigades"
                :value="active_brigade"
                v-model="active_brigade"
              ></custom-select>
            </div>
          </div>
          <div class="modal-content-section">
            <p class="bold">Комментарий заказчика</p>
            <textarea
              v-model="order.comment"
              placeholder="Напишите комментарий"
              class="form-control mb-3"
            ></textarea>
          </div>
          <div class="modal-content-section">
            <p class="bold">Стоимость</p>
            <div class="d-flex justify-content-between">
              <div class="active-order-cost_left">
                <p class="gray-txt">Рекомендованная стоимость</p>
                <h2>{{ recommendedCost }}₽</h2>
              </div>
              <div class="w-50">
                <custom-input
                  :label="'Установленная стоимость'"
                  v-model="order.cost"
                  :value="order.cost"
                  :placeholder="8000"
                ></custom-input>
              </div>
            </div>
          </div>
        </template>

        <!-- PREV-NEXT BUTTONS -->
        <div class="brigade-btns w-100 d-flex justify-content-end mt-2">
          <custom-button
            v-if="window > 1"
            :text="'Назад'"
            :blank="true"
            :with_border="true"
            :padding="'1em'"
            @click="
              () => {
                window--;
              }
            "
          ></custom-button>
          <custom-button
            :text="'Продолжить'"
            :right_arrow="true"
            :padding="'1em'"
            @click="nextWindow"
          ></custom-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "CreateOrderPage",
  components: {
    // VueTimepicker,
    // VueDatePicker,
  },
  props: {
    edited_order: {
      default: false,
    },
  },
  data() {
    return {
      brigades: [],
      active_brigade: {},
      select_brigade: false,
      order: {
        address: {
          to: {
            coords: {
              lat: "",
              lon: "",
            },
            description: "",
            full: "",
            title: "",
          },
          from: {
            coords: {
              lat: "",
              lon: "",
            },
            description: "",
            full: "",
            title: "",
          },
          extra: [],
        },
        comment: "",
        brigade_id: "",
        cost: "",
        dateNone: false,
        dateRide: null,
        name: null,
        needRideBack: false,
        pending: false,
        phone: null,
        self_brigade: false,
        services: [],
      },
      recommendedCost: 0,
      window: 1,
      dateRide: {
        date: "",
        time: "",
      },
      services: {},
      desStairs: {
        checked: false,
        floor: "",
        weight: "",
      },
      climbingStairs: {
        checked: false,
        floor: "",
        weight: "",
      },
      loaded: false,
    };
  },
  methods: {
    nextWindow() {
      this.order.dateRide = this.dateRide.date + " " + this.dateRide.time;
      console.log("this.order");
      console.log(this.order);
      console.log("this.dateRide.date");
      console.log(this.dateRide.date);
      console.log("this.dateRide.time");
      console.log(this.dateRide.time);
      if (this.window < 4) {
        if (this.window == 3 && !this.isOrderCompleted()) {
          console.log("The order is not completed!");
        } else {
          this.window++;
        }
      } else {
        // if(this.isOrderCompleted()) {
        this.packBrigade();
        // this.order.status = this.order.status.status;
        if (this.$route.params.id) {
          // ЕСЛИ ПРАВИМ СУЩЕСТВУЮЩИЙ ЗАКАЗ?
          this.$http
            .post("/editOrder/" + this.order.id, this.order)
            .then(() => {
              this.$router.push("/orders");
            });
        } else {
          // ЕСЛИ СОЗДАËМ НОВЫЙ
          this.$http.post("/createOrder", this.order).then(() => {
            this.$router.push("/orders");
          });
        }
      }
    },
    goToMyOrders() {
      this.$router.push("/orders");
    },
    // selectDate(date) {
    //   this.dateRide.date = this.$moment(date).format("DD.MM.YYYY");
    // },
    // selectTime(time) {
    //   this.dateRide.time = this.$moment(time).format("HH:ii:SS");
    // },
    addAddress() {
      const address = {
        coords: {
          lat: "",
          lon: "",
        },
        description: "",
        full: "",
        title: "",
      };

      this.order.address.extra.push(address);
    },
    getServices() {
      this.$http.get("/services").then((res) => {
        this.services = res.data.data;
        this.services.forEach((service) => {
          service.selected = false;
        });
      });
    },
    packServices() {
      const services = [];
      if (this.desStairs.checked) {
        const desStairs = {
          floor: this.desStairs.floor,
          weight: this.desStairs.weight,
          desStairs: true,
        };
        services.push(desStairs);
      }
      if (this.climbingStairs.checked) {
        const climbingStairs = {
          floor: this.climbingStairs.floor,
          weight: this.climbingStairs.weight,
          climbingStairs: true,
        };
        services.push(climbingStairs);
      }
      this.services.forEach((service) => {
        if (service.selected) {
          services.push({ service_id: service.id });
        }
      });

      this.order.services = services;
    },
    packDateRide() {
      //   let dateFormat = "DD.MM.YYYY";
      //   let dateRide = null;
      //   if (this.order.dateRide) {
      //     dateRide =
      //       this.$moment(this.dateRide.date, dateFormat).format("DD.MM.YYYY") +
      //       " " +
      //       this.dateRide.time;
      //   }
      //   this.order.dateRide = dateRide;
      this.order.dateRide = this.order.dateRide.date + this.order.dateRide.time;
    },
    packBrigade() {
      if (!this.active_brigade.id) {
        this.order.self_brigade = true;
      } else {
        this.order.brigade_id = this.active_brigade.id;
      }
    },
    getBrigades() {
      this.$http.get("/brigades").then((res) => {
        this.brigades = res.data.data;
        this.loaded = true;
      });
    },
    isOrderCompleted() {
      if (this.order.name && this.order.phone) return true;
      else return false;
    },
  },
  watch: {
    window: function (val) {
      if (val == 4 && this.isOrderCompleted()) {
        this.$http.post("/calculateOrder", this.order).then((res) => {
          this.recommendedCost = res.data.data.recommendedCost;
        });
        this.packServices();
        this.packDateRide();
      }
    },
  },
  computed: {
    additional_addreses() {
      return this.order.address.slice(2);
    },
  },
  mounted() {
    this.getServices();
    this.getBrigades();
    this.packBrigade();
    console.log("this.$route");
    console.log(this.$route);
    console.log(this.$route.params.id);
    // console.log(this.order_completed);
    if (this.$route.params.id) {
      this.loaded = false;
      this.$http.get("/order/" + this.$route.params.id).then((res) => {
        this.order = res.data.data;
        this.loaded = true;
        this.dateRide.date = this.order.dateRide.split(" ")[0];
        this.dateRide.time = this.order.dateRide.split(" ")[1];
        this.services.forEach((service) => {
          this.order.services.forEach((or_service) => {
            if (or_service.id == service.id) service.selected = true;
          });
        });
      });
    }
  },
};
</script>
<style>
.bgredcustom {
  background-color: rgb(219, 85, 85) !important;
}
.bgredcustom p {
  color: white !important;
}

.arrow-left-icon {
  background: url("@/assets/images/28.svg") no-repeat center center;
}

.arrow-right-icon {
  background: url("@/assets/images/arrow-gray.svg") no-repeat center center;
}

.menu-item_active {
  background: #ececf1;
}
.hours .hint {
  display: none;
}
.minutes .hint {
  display: none;
}
th {
  text-align: center;
}
</style>
