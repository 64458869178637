<template>
    <div class="custom_checkbox" 
    :class="{'gray-bg' : background}" 
    :style="'padding:' + bg_padding + ';'">
        <div class="custom_checkbox_block">
            <input type="checkbox" 
            :name="input_name" 
            :id="custom_name">
            <span class="checkbox-mask" 
            :class="{'checked_mask' : checked, 'checkbox-mask-background' : background}" 
            @click="onCheck"></span>
            <label :for="input_name" @click="onCheck" :class="{'bold-small' : bold}">{{label}}</label>
        </div>
        <div class="custom_checkbox_options custom_checkbox_block" v-if="checked && options.length > 0">
            <template v-for="(option, index) in options_selected" :key="index">
                <input type="checkbox" name="options" id="options">
                <span class="checkbox-mask" :class="{'checked_mask' : option.checked, 'checkbox-mask-background' : background}" @click="checkOption(option)"></span>
                <label for="options" @click="checkOption(option)" :class="{'bold' : bold}">{{option.name}}</label>
            </template>
            
        </div>
        
        <!-- <p class="bold">{{label}}</p> -->
    </div>
</template>
<script>
export default {
    name: "CustomCheckbox",
    props: {
        label: {
            default: ''
        },
        option_id: {
            default: ''
        },
        input_name: {
            default: ''
        },
        background: {
            default: false
        },
        bg_padding: {
            default: 0
        },
        bold: {
            default: false
        },
        options: {
            default: false
        },
        checked: {
            default: false
        }
        // label: {
        //     default: ''
        // }
    },
    data() {
        return {
            // checked: false,
            options_selected: []
        }
    },
    methods: {
        onCheck() {
            /*this.checked = !this.checked;*/
            this.$emit('update:modelValue', !this.checked);
        },
        checkOption(option) {
            option.checked = !option.checked;
            this.$emit('checkOption', this.option_id, this.options_selected);
        },
    },
    mounted() {
        if(this.options) {
            this.options_selected = this.options;
        }
    }
}
</script>
<style>
.custom_checkbox {
    display: flex;
    flex-direction: column;
}

.custom_checkbox_block {
    margin: 0.8em 0;
    flex-direction: row;
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
}

.custom_checkbox_options {
    margin-left: 1em;
}

.custom_checkbox_block label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7D8592;
    /* position: relative; */
    padding-left: 15%;
    margin-left: 0;
    cursor: pointer;
}

.custom_checkbox_block input{
    background: #3D65BA;
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
}

.checkbox-mask {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    /* transform: translateY(-50%); */
    width: 22px;
    height: 22px;
    border: 2px solid #3D65BA;
    border-radius: 5px;
}

.checkbox-mask-background {
    top: 5%;
    left: 5%;
}

.checked_mask {
    background: url('@/assets/images/check.svg') no-repeat center center;
}

.gray-bg {
    /* padding: 16px; */
    background: #F7F7FC;
    border: 1px solid #E6E6F0;
    border-radius: 16px;
    margin: 16px 0;
}
</style>
