// import Vue from 'vue';
// Vue.use(VueRouter);
import { createRouter, createWebHistory } from "vue-router";
import Auth from "@/config/auth";

import LoginPhonePage from "@/components/pages/login/LoginPhone";
import SignUpPhonePage from "@/components/pages/login/SignUpPhone";
import LoginPasswordPage from "@/components/pages/login/LoginPassword";
import SignUpPasswordPage from "@/components/pages/login/SignUpPassword";
import MainLoginPage from "@/components/pages/login/MainLogin";

import IndexPage from "@/components/pages/profile/IndexPage";
import MyOrdersPage from "@/components/pages/orders/MyOrdersPage";
import CreateOrderPage from "@/components/pages/orders/CreateOrderPage";
import BrigadesPage from "@/components/pages/brigades/BrigadesPage";

const routes = [
    // {
    //     path: '/',
    //     redirect: '/login'
    // },
    {
        path: '/login',
        name: 'LoginPhone',
        component: LoginPhonePage,
        meta: {
            requiresAuth: false
        },
        // children: [
        //     {
        //         path: 'password',
        //         component: LoginPasswordPage 
        //     },
        // ]
    },
    {
        path: '/login/main',
        name: 'MainLogin',
        component: MainLoginPage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signup',
        name: 'SignUpPhone',
        component: SignUpPhonePage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login/password',
        name: 'LoginPassword',
        component: LoginPasswordPage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signup/password',
        name: 'SignUpPassword',
        component: SignUpPasswordPage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: IndexPage,
        meta: {
            requiresAuth: true
        } 
    },
    {
        path: '/orders',
        name: 'Orders',
        component: MyOrdersPage,
        meta: {
            requiresAuth: true
        } 
    },
    {
        path: '/orders/create/:id?',
        name: 'CreateOrder',
        component: CreateOrderPage,
        meta: {
            requiresAuth: true
        } 
    },
    {
        path: '/brigades',
        name: 'BrigadesIndex',
        component: BrigadesPage,
        meta: {
            requiresAuth: true
        } 
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if(to.meta.requiresAuth) {
        if(!Auth.check()) {
            router.push('/login');
        }
    } else {
        if(Auth.check()) {
            router.push('/dashboard');
        } 
    }
    next();
});

export default router;



// export default router;
