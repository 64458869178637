<template>
    <custom-modal height="500" 
    width="700" 
    left="25"
    @close="close"
    class="ps-3 pe-3"
    :title="'Информация об организации'">
       <div class=" modal-content-section ms-3 me-3">
           <div class="d-flex mb-3 w-100 justify-content-between align-items-center">
               <p class="bold m-0">Фото</p>
               <custom-button
               padding="0.5em"
               @click="fireFile"
               text="Редактировать"></custom-button>
               <input id="company_img" type="file" name="" hidden @input="loadImage">
           </div>
           <p class="gray-txt d-block mb-3 w-100 text-center">Добавьте фото профиля, чтобы заказчикам было проще Вас узнать</p>
           <div class="d-flex w-100 justify-content-between mt-2">
                <template v-if="user.avatar && loaded" >
                   <round-image height="100" width="100" :url="user.avatar"></round-image>
                   <div class="w-75">
                       <p class="red-bold">Выбран</p>
                       <p class="gray-small">Размер файла должен быть не более 20 Мб PNG, JPG</p>
                   </div>
                </template>
                <template v-else>
                   <round-image class="" height="100" :width="'100'" :path="'images/avatar.svg'" :key="loaded"></round-image>
                   <div class="w-75">
                       <p class="red-bold">Не выбрано</p>
                       <p class="gray-small">Размер файла должен быть не более 20 Мб <br> PNG, JPG</p>
                   </div>
                </template>

           </div>
       </div>
       <div class="modal-content-section ms-3 me-3">
           <div class="d-flex w-100 mb-3 justify-content-between align-items-center">
               <p class="bold m-0">Об организации</p>
               <custom-button
               padding="0.5em"
               text="Редактировать" @click="editCompanyType = true"></custom-button>
           </div>
           <p class="gray-small">Тип организации</p>
           <p v-if="!editCompanyType" class="red-bold mb-3">{{active_type.name ?? 'Не указано'}}</p>
           <div class="d-flex w-50" v-else>
               <custom-select 
                placeholder="Тип организации"
                :options="org_types"
                :value="active_type"
                v-model="active_type"
                label=""
                :top="45"
                class="w-100"></custom-select>
                <span class="icon icon-success ml-3 mt-3" @click="updateCompanyType"></span>
                <img src="@/assets/images/delete.svg" @click="editCompanyType = false"> 
           </div>


       </div>
       <div class="modal-content-section ms-3 me-3">
            <div class="d-flex justify-content-between w-100">
                <div>
                    <p class="gray-small">Название</p> 
                    <custom-input v-if="editCompanyInfo" 
                    :value="company.name" 
                    v-model="company.name" 
                    placeholder="Название"
                    class="w-50"
                    ></custom-input>
                    <p v-else class="red-bold">{{company.name ?? 'Не указано'}}</p>
                </div>
                <custom-button
                v-if="!editCompanyInfo"
                :padding="'0.5em'"
                :text="'Редактировать'"
                @click="editCompanyInfo = true"
                ></custom-button>
                <div v-else class="d-flex">
                    <span class="icon icon-success ml-3 mt-3" @click="sendComapnyInfo"></span>
                    <span class="icon icon-delete ml-3 mt-3" @click="removeChangesInfo"></span>
                </div>

            </div>
            <div class="d-flex mt-2 w-100 justify-content-between">
                <div>
                    <p class="gray-small">ИНН</p>
                    <custom-input v-if="editCompanyInfo"
                    :value="company.inn"
                    v-model="company.inn"
                    placeholder="ИНН"
                    ></custom-input>
                    <p v-else class="red-bold">{{company.inn ?? 'Не указано'}}</p>
                </div>
                <div>
                    <p class="gray-small">ОГРН</p>
                    <custom-input v-if="editCompanyInfo"
                    :value="company.ogrn"
                    v-model="company.ogrn"
                    placeholder="ОГРН"
                    ></custom-input>
                    <p v-else class="red-bold">{{company.ogrn ?? 'Не указано'}}</p>
                </div>
                <div>
                    <p class="gray-small">КПП</p>
                    <custom-input v-if="editCompanyInfo"
                    :value="company.kpp"
                    v-model="company.kpp"
                    placeholder="КПП"
                    ></custom-input>
                    <p v-else class="red-bold">{{company.kpp ?? 'Не указано'}}</p>
                </div>

            </div>
            <p class="gray-small">Юридический адрес</p>
            <custom-input v-if="editCompanyInfo"
            :value="company.legalAddress"
            v-model="company.legalAddress"
            placeholder="Юридический адрес"
            ></custom-input>
            <p v-else class="red-bold">{{company.legalAddress ?? 'Не указано'}}</p>
       </div>
    </custom-modal>
</template>
<script>
import {store} from '@/config/store.js';
export default {
    name: "CompanyModal",
    props: {
       id: {
            default: ''
       } 
    },
    data() {
        return {
            store,
            company: {},
            user: {},
            path: '',
            loaded: false,
            editCompanyType: false,
            editCompanyInfo: false,
            org_types: [
                {
                    name: 'ООО',
                    value: 'OOO'
                },
                {
                    name: 'ИП',
                    value: 'I'
                },
                {
                    name: 'Физическое лицо',
                    value: 'F'
                }
            ],
            active_type: {}
        }

    },
    methods: {
        close() {
            /*store.show_notifications = false,*/
            this.$emit('update:modelValue', false);
        },
        sendComapnyInfo() {
            this.$http.post('/users/update', {company: this.company}).then(() => {
                this.getData();
                this.editCompanyInfo = false;
            })
        },
        removeChangesInfo() {
            this.getData();
            this.editCompanyInfo = false;
        },
        updateCompanyType() {
            this.company.company_type = this.active_type.value;
            this.$http.post('/users/update', {company: this.company}).then(() => {
                this.getData();
                this.editCompanyType = false;
            })
        },
        fireFile() {
            document.getElementById('company_img').click();
        },
        loadImage(e) {
            const file = e.target.files[0];
            let formData = new FormData();
            formData.append('media[]', file);
            this.loaded = false;
            this.$http.post('/uploadMedia', formData).then(res => {
                const avatar = res.data.data[0].medium;
                console.log(avatar);
                this.$http.post('/users/update', {avatar: avatar}).then(() => {
                    this.getData();
                })
            })

        },
        getData() {
            this.$http.get('/users/self').then(res => {
                const empty_company = {
                    'birthDay': '',
                    'company_type': '',
                    'depCode': '',
                    'inn': '',
                    'issueDate': '',
                    'issuedBy': '',
                    'name': '',
                    'serialAndNumber': ''
                };
                this.company = res.data.data.company ?? empty_company;
                this.user = res.data.data;
                //if(!this.user.company) this.company = [];
                if(this.company.company_type) {
                    this.org_types.forEach(t => {
                        if(t.value == this.company.company_type) {
                            this.active_type = t;
                        }
                    })
                }
                this.loaded = true;
                /*this.path = this.user.avatar ?? '@/assets/images/user.jpg'*/
            })
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
<style>
.red-bold {

    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #DB5555;
}
</style>
