<template>
    <div class="form-footer" :style="styles">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "FormFooter",
    props: {
        padding: {
            default: "9px 8px"
        }
    },
    data() {
        return {
            styles: {
                padding: this.padding
            }
        }
    }
}
</script>
<style>
.form-footer {
    border-top: 1px solid #E6E6F0;
    display: flex;
    align-items: center;
}
</style>