<template>
    <div class="input-container">
        <label for="some-input" class="custom-input-label" :class="{'disabled-label': disabled}" v-if="label.length > 0">{{label}}</label>
        <input type="text" class="custom-input" :placeholder="placeholder" @input="passValue" :disabled="disabled" :value="value">
    </div>
</template>
<script>

export default {
    name: "CustomInput",
    props: {
        label: {
            default: ""
        },
        placeholder: {
            default: "Type..."
        },
        disabled: {
            default: false
        },
        value: {
            default: ''
        }
    },
    methods: {
        passValue(e) {
            this.$emit('update:modelValue', e.target.value);
        }
    }
}
</script>
<style>
.disabled-label {
    color: #B6B6BF !important;;
}
</style>