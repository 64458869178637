import {reactive} from 'vue';
import Auth from "@/config/auth";
// import instance from "@/config/axios";

export const store = reactive({
    //Проверка пользователя на вход
    loggedIn: Auth.check(),
    //Не используется
    user: JSON.parse(localStorage.getItem('dispatchers_viuviu_user')) ?? [],
    token: localStorage.getItem('dispatchers_viuviu_token'),
    //Этот флаг отвечает за модальное окно после регистрации
    new_user: Auth.isNewUser(),

    /**
     * ФЛАГИ КОТОРЫЕ ОТКРЫВАЮТ ВКЛАДКИ НАВБАРА
     */

    navbar: {
        //Информация об ораганизации - показывать/нет
        show_company: false,

        //Уведомления по кнопке из навбара
        show_notifications: false,

        //Настройки калькулятора
        show_calculator: false,
    },


    /**
     * МЕТОДЫ
     */
    setPhone(phone, remember = false) {
        localStorage.setItem('dispatchers_viuviu_phone', phone);
        if(remember) localStorage.setItem('remember_me', true);
        this.user.push({"phone": phone});
    },
    showCompany() {
        Object.keys(this.navbar).forEach(key => {
            this.navbar[key] = false;
        });

        this.navbar.show_company = true;
    },

    showNotifications() {
        Object.keys(this.navbar).forEach(key => {
            this.navbar[key] = false;
        });

        this.navbar.show_notifications = true;
    },

    showCalculator() {
        Object.keys(this.navbar).forEach(key => {
            this.navbar[key] = false;
        });

        this.navbar.show_calculator = true;
    },


});
