<template>
  <custom-modal
    v-if="show_modal"
    title="Вы точно хотите выйти?"
    @close="close"
    height="200"
    top="20"
  >
    <div class="btns mt-5 d-flex w-100 justify-content-evenly">
      <custom-button text="Да" @click="logout"></custom-button>
      <custom-button
        :blank="true"
        :with_border="true"
        text="Нет"
        @click="close"
      ></custom-button>
    </div>
  </custom-modal>
  <div class="custom-navbar" v-if="loaded">
    <div class="custom-navbar_search input-container">
      <input
        type="text"
        class="custom-input"
        placeholder="Искать бригаду, заказ и прочее..."
      />
      <span class="custom-input-icon search-icon"></span>
    </div>
    <div class="custom-navbar_user" @click="show_submenu = !show_submenu">
      <custom-button
        :blank="true"
        :href="'#'"
        :icon="true"
        :with_border="true"
        class="custom-navbar_user-notif"
        :padding="'0.8em'"
        :border_color="'#E6E6F0'"
        @click="store.show_notifications = true"
      >
        <span class="notif-icon" @click="showNotificationsModal = true"></span>
      </custom-button>
      <custom-button
        :blank="true"
        :href="'#'"
        :icon="true"
        :with_border="true"
        class="custom-navbar_user-info d-flex align-items-center"
        :padding="0"
        :border_color="'#E6E6F0'"
        :disabled="true"
      >
        <round-image v-if="user.avatar" :url="user.avatar"></round-image>
        <round-image v-else :path="'images/user.jpg'"></round-image>
        <p class="mb-2 bold-small me-4 ms-2">
          {{ user.company ? user.company.name : "Привет, незнакомец!" }}
        </p>
        <span class="custom-input_icon arrow-down-icon"></span>
      </custom-button>
      <div class="custom-navbar_submenu" v-if="show_submenu">
        <ul class="custom-navbar_submenu-list">
          <li @click="showCompany" class="custom-navbar_submenu-item">
            <a href="#">Информация об организации</a>
          </li>
          <li @click="showCalculator" class="custom-navbar_submenu-item">
            <a href="#">Настройки калькулятора</a>
          </li>
          <li class="custom-navbar_submenu-item">
            <a href="#">Условия пользования</a>
          </li>
          <li
            class="custom-navbar_submenu-item exit"
            @click="show_modal = true"
          >
            <a href="#">Выйти из аккаунта</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "@/config/store";

export default {
  name: "NavBar",
  data() {
    return {
      store,
      show_submenu: false,
      user: {},
      loaded: false,
      show_modal: false,
    };
  },
  methods: {
    logout() {
      this.show_modal = false;
      this.$auth.logout();
      location.reload();
    },
    getUser() {
      this.$http.get("/users/self").then((res) => {
        this.user = res.data.data;
        this.loaded = true;
      });
    },
    showCompany() {
      this.store.showCompany();
    },
    showCalculator() {
      this.store.showCalculator();
    },
    close() {
      this.show_modal = false;
    },
  },
  // computed: {
  //     user() {
  //         return localStorage.getItem('dispatchers_viuviu_user');
  //     }
  // }
  mounted() {
    this.getUser();
  },
};
</script>
<style>
.search-icon {
  background: url("@/assets/images/search.svg") no-repeat center center;
}

.arrow-down-icon {
  background: url("@/assets/images/arrow.svg") no-repeat center center;
}

.notif-icon {
  background: url("@/assets/images/notifi.svg") no-repeat center center;
  height: 20px;
  width: 20px;
  display: block;
}

.custom-navbar {
  width: 100%;
  padding: 13px 8%;
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.custom-navbar_search {
  width: 30%;
}

.custom-navbar_user {
  display: flex;
}

.custom-navbar_user-info button {
  height: 100%;
  padding: 0 5px !important;
}
.custom-navbar_user-info button p {
  height: 100%;
  margin: 0 10px !important;
  padding: 0 !important;
}
.custom-navbar_user-info button span {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.custom-navbar_user-info p {
  color: black;
  padding-top: 1em;
  margin-left: 1em;
  margin-right: 2em;
  /* margin-top: 45%; */
}

.custom-navbar_user-notif {
  width: 45px;
  height: 100%;
  margin-right: 1em;
  display: block;
}
.custom-navbar_user-notif button {
  height: 100%;
}

.custom-navbar_submenu {
  width: 100%;
  position: absolute;
  top: 10%;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #e6e6f0;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
  min-width: 232px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
}

.custom-navbar_submenu-list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-navbar_submenu-item {
  width: 100%;
}

.custom-navbar_submenu-item a {
  width: 100%;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #292930;
}

.exit {
  border-top: 1px #e6e6f0 solid;
}

.exit a {
  color: #db5555 !important;
}
</style>
