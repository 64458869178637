/**
 * Тут методы для входа и подставления токена на каждом запросе
 */
import axios from "axios";

class Auth {
    constructor() {
        this.token = window.localStorage.getItem("dispatchers_viuviu_token");
        this.exp_time = window.localStorage.getItem("dispatchers_viuviu_token_exp");
        const userData = window.localStorage.getItem("dispatchers_viuviu_user");
        this.userData = userData ? JSON.parse(userData) : null;
        // this.axios = instance;
        axios.defaults.baseURL = 'https://drivers.viuviu.ru/api/v1/';
        if(this.token) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
        }
    }


    login(token) {
        let login = new Promise((resolve) => {
            localStorage.removeItem('dispatchers_viuviu_phone');
            localStorage.setItem('dispatchers_viuviu_token', token);
            let exp = JSON.parse(localStorage.getItem('remember_me')) == true ? 0 : new Date().getTime();
            localStorage.setItem('dispatchers_viuviu_token_exp', exp);
            this.token = token;
            localStorage.removeItem('remember_me');
            resolve(true);
        });
        
        login.then(() => {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
            //axios.get('users/self').then(res => {
                //localStorage.setItem("dispatchers_viuviu_user", JSON.stringify(res.data.data)); 
            //});
            window.location.reload();
        });
        
    }

    setUser(user) {
        window.localStorage.setItem('userData', user);
    }

    check() {
        if(!this.token) return false;

        if(this.exp_time != 0 && new Date().getTime() - this.exp_time  > 60 * 60 * 1000) return false;

        return true;
    }

    isNewUser() {
        if(JSON.parse(localStorage.getItem('new_user')) == true) return true;
        else return false;
    }

    logout() {
        window.localStorage.removeItem('dispatchers_viuviu_token');
        window.localStorage.removeItem('dispatchers_viuviu_token_exp');
        window.localStorage.removeItem('dispatchers_viuviu_token_user');
        this.token = null;
    }

}

export default new Auth();
